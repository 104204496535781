import { Icon } from "@mui/material";
import MDBox from "components/MDBox";

function RenderUserScore(userScore: number) {
  return (
    <MDBox display={"flex"} alignItems={"center"} gap={1}>
      <Icon color="warning" fontSize="small">
        star
      </Icon>
      {userScore === 0 ? "평가 없음" : userScore}
    </MDBox>
  );
}
export default RenderUserScore;
