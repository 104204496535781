import { ColumnsType } from "antd/es/table";

import { Icon } from "@mui/material";
import { IExpWorkList } from "apis/response";
import MDBox from "components/MDBox";
import { calculateAge } from "utils/utilDate";
import RenderUserScore from "components/RenderUserScore";
import { emptyString } from "utils";

const tableColumn: ColumnsType<IExpWorkList> = [
  {
    title: "이름",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
  },
  {
    title: "평점",
    dataIndex: "userScore",
    key: "userScore",
    ellipsis: true,

    render: RenderUserScore,
  },
  {
    title: "나이",
    dataIndex: "birth",
    key: "birth",
    ellipsis: true,
    render: (birth: string) => calculateAge(birth ?? ""),
  },
  {
    title: "혈압",
    dataIndex: "bloodPressure",
    key: "bloodPressure",
    ellipsis: true,
    render: (bloodPressure: string) => emptyString(bloodPressure),
  },

  {
    title: "연락처",
    dataIndex: "phone",
    key: "phone",
    ellipsis: true,
    render: (phone: string) => emptyString(phone),
  },
  {
    title: "계좌번호",
    dataIndex: "bankAccount",
    key: "bankAccount",
    ellipsis: true,
    render: (bankAccount) => emptyString(bankAccount),
  },
  {
    title: "은행명",
    dataIndex: "bankName",
    key: "bankName",
    ellipsis: true,
    render: (bankName) => emptyString(bankName),
  },
  {
    title: "예금주",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
  },
  {
    title: "출근 현장",
    dataIndex: "title",
    key: "title",
    align: "center",
    ellipsis: true,
  },
];
export { tableColumn };
