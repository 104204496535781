import { Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { WorkData } from "apis/response";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";

interface newWork {
  title: string;
  data: any[];
}
const example: newWork[] = [
  {
    title: "2024-04-18",
    data: [
      {
        workId: 102,
        workCreatorId: 12,
        workCreatorType: "employmentAgency",
        workCreatorName: "픽스잇 수리업체",
        title: "마포구 아파트 단수 수리",
        status: "모집중",
        workStart: "2024-04-18 11:00:00",
        workEnd: "2024-04-18 17:00:00",
        workDay: "2024-04-18 00:00:00",
        workTime: "11:00:00~17:00:00",
        maxRecruitment: 2,
        currentRecruitment: 1,
        currentApplicants: 3,
        currnetConfirmedAttendees: 1,
        currentAbsentees: 0,
        manDay: 1,
        pay: 100000,
        workNumber: "",
        payType: "일급",
        address: "서울 마포구 월드컵북로 1",
        company: "픽스잇 수리업체",
        managerName: "김수리",
        managerPhone: "01099887766",
        requiredExperience: "보통인부 2년",
        content: "간식 제공\n안전모 착용",
        image: "",
        lat: "37.556838",
        lng: "126.898101",
      },
    ],
  },
  {
    title: "2024-04-17",
    data: [
      {
        workId: 101,
        workCreatorId: 11,
        workCreatorType: "employmentAgency",
        workCreatorName: "그린가든 조경",
        title: "용산 공원 조경 정비 작업",
        status: "모집완료",
        workStart: "2024-04-17 09:00:00",
        workEnd: "2024-04-17 15:00:00",
        workDay: "2024-04-17 00:00:00",
        workTime: "09:00:00~15:00:00",
        maxRecruitment: 4,
        currentRecruitment: 4,
        currentApplicants: 6,
        currnetConfirmedAttendees: 4,
        currentAbsentees: 0,
        manDay: 1,
        pay: 120000,
        workNumber: "",
        payType: "일급",
        address: "서울 용산구 서빙고로 137",
        company: "그린가든 조경",
        managerName: "정원식",
        managerPhone: "01033221100",
        requiredExperience: "경험 무관",
        content: "점심 제공\n장비 제공",
        image: "",
        lat: "37.532600",
        lng: "126.990975",
      },
    ],
  },
  {
    title: "2024-04-16",
    data: [
      {
        workId: 95,
        workCreatorId: 9,
        workCreatorType: "employmentAgency",
        workCreatorName: "항래짱짱인력",
        title: "서울 공덕동 경찰공제회 건물 유리통창 교체작업",
        status: "모집완료",
        workStart: "2024-03-28 11:30:00",
        workEnd: "2024-03-28 12:30:00",
        workDay: "2024-03-28 00:00:00",
        workTime: "13:00:00~16:00:00",
        maxRecruitment: 2,
        currentRecruitment: 2,
        currentApplicants: 0,
        currnetConfirmedAttendees: 2,
        currentAbsentees: 0,
        manDay: 1,
        pay: 288890,
        workNumber: "",
        payType: "일급",
        address: "서울 마포구 마포대로 78",
        company: "주식회사주주클럽",
        managerName: "김항래",
        managerPhone: "01099239397",
        requiredExperience: "보통인부 3년",
        content: "간식제공\n주차가능\n퇴직공제",
        image: "images/origin/work/202403/cf24133f-76ac-4741-b0bb-b9961d69e7ac.jpeg",
        lat: "37.5419163",
        lng: "126.9498023",
      },
      {
        workId: 92,
        workCreatorId: 9,
        workCreatorType: "employmentAgency",
        workCreatorName: "항래짱짱인력",
        title: "서울 공덕동 경찰공제회 건물 유리통창 교체작업",
        status: "모집완료",
        workStart: "2024-03-28 12:30:00",
        workEnd: "2024-03-28 13:30:00",
        workDay: "2024-03-31 00:00:00",
        workTime: "13:00:00~16:00:00",
        maxRecruitment: 4,
        currentRecruitment: 3,
        currentApplicants: 2,
        currnetConfirmedAttendees: 2,
        currentAbsentees: 0,
        manDay: 1,
        pay: 288890,
        workNumber: "",
        payType: "일급",
        address: "서울 마포구 마포대로 78",
        company: "주식회사주주클럽",
        managerName: "김항래",
        managerPhone: "01099239397",
        requiredExperience: "보통인부 3년",
        content: "간식제공\n주차가능\n퇴직공제",
        image: "images/origin/work/202403/cf24133f-76ac-4741-b0bb-b9961d69e7ac.jpeg",
        lat: "37.5419163",
        lng: "126.9498023",
      },
    ],
  },
  {
    title: "2024-04-15",
    data: [
      {
        workId: 90,
        workCreatorId: 9,
        workCreatorType: "employmentAgency",
        workCreatorName: "항래짱짱인력",
        title: "서울 공덕동 경찰공제회 건물 유리통창 교체작업",
        status: "모집완료",
        workStart: "2024-03-28 11:30:00",
        workEnd: "2024-03-28 12:30:00",
        workDay: "2024-03-28 00:00:00",
        workTime: "13:00:00~16:00:00",
        maxRecruitment: 2,
        currentRecruitment: 1,
        currentApplicants: 6,
        currnetConfirmedAttendees: 2,
        currentAbsentees: 0,
        manDay: 1,
        pay: 288890,
        workNumber: "",
        payType: "일급",
        address: "서울 마포구 마포대로 78",
        company: "주식회사주주클럽",
        managerName: "김항래",
        managerPhone: "01099239397",
        requiredExperience: "보통인부 3년",
        content: "간식제공\n주차가능\n퇴직공제",
        image: "images/origin/work/202403/cf24133f-76ac-4741-b0bb-b9961d69e7ac.jpeg",
        lat: "37.5419163",
        lng: "126.9498023",
      },
      {
        workId: 89,
        workCreatorId: 9,
        workCreatorType: "employmentAgency",
        workCreatorName: "항래짱짱인력",
        title: "서울 공덕동 경찰공제회 건물 유리통창 교체작업",
        status: "모집완료",
        workStart: "2024-03-28 10:00:00",
        workEnd: "2024-03-28 10:30:00",
        workDay: "2024-03-28 00:00:00",
        workTime: "10:45:00~12:00:00",
        maxRecruitment: 3,
        currentRecruitment: 1,
        currentApplicants: 3,
        currnetConfirmedAttendees: 1,
        currentAbsentees: 0,
        manDay: 1,
        pay: 288890,
        workNumber: "",
        payType: "일급",
        address: "서울 마포구 마포대로 78",
        company: "주식회사주주클럽",
        managerName: "김항래",
        managerPhone: "01099239397",
        requiredExperience: "보통인부 3년",
        content: "간식제공\n주차가능\n퇴직공제",
        image: "images/origin/work/202403/cf24133f-76ac-4741-b0bb-b9961d69e7ac.jpeg",
        lat: "37.5419163",
        lng: "126.9498023",
      },
    ],
  },
];
const tableColumn: ColumnsType<WorkData> = [
  {
    title: "현장명",
    dataIndex: "title",
    key: "title",
    width: "25%",
    ellipsis: true,
    render: (title: string) => (
      <Typography
        variant="h5"
        fontWeight={700}
        style={{
          maxWidth: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
    ),
  },
  {
    title: "모집인원",
    dataIndex: "maxRecruitment",
    key: "maxRecruitment",
    ellipsis: true,
    render: (maxRecruitment: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              모집인원
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {maxRecruitment}
        </Typography>
      </MDBox>
    ),
  },

  {
    title: "지원자",
    dataIndex: "workApplyCount",
    key: "workApplyCount",
    ellipsis: true,
    render: (workApplyCount: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              지원자
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {workApplyCount}
        </Typography>
      </MDBox>
    ),
  },
  {
    title: "지원승인",
    dataIndex: "currentRecruitment",
    key: "currentRecruitment",
    ellipsis: true,
    render: (currentRecruitment: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              지원승인
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {currentRecruitment}
        </Typography>
      </MDBox>
    ),
  },
  {
    title: "출근자",
    dataIndex: "goWorkCount",
    key: "goWorkCount",
    ellipsis: true,
    render: (goWorkCount: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              출근자
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {goWorkCount}
        </Typography>
      </MDBox>
    ),
  },
  {
    title: "결근자",
    dataIndex: "workAbsenceCount",
    key: "workAbsenceCount",
    ellipsis: true,
    render: (workAbsenceCount: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              결근자
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {workAbsenceCount}
        </Typography>
      </MDBox>
    ),
  },
];
export { tableColumn };
export type { newWork };
