import { GraphOne, GraphThree, GraphTwo } from "apis/response";

function transformData(data1: GraphOne[]) {
  const labels = data1.map((item) => item.workDay);
  const data = data1.map((item) => item.totalWorkCount);

  return {
    labels,
    datasets: [
      {
        label: "등록공고 수",
        color: "dark",
        data,
      },
    ],
  };
}

function transformData2(data2: GraphTwo[]) {
  const labels = data2.map((item) => item.workDay);
  const totalWorkAbsenceCountData = data2.map((item) => item.totalWorkAbsenceCount);
  const totalGoWorkCountData = data2.map((item) => item.totalGoWorkCount);
  const totalMaxRecruitmentData = data2.map((item) => item.totalMaxRecruitment);

  return {
    labels,
    datasets: [
      {
        chartType: "thin-bar",
        label: "총 결근자 수(명)",
        color: "info",
        data: totalWorkAbsenceCountData,
      },
      {
        chartType: "gradient-line",
        label: "총 출근자 수(명)",
        color: "dark",
        data: totalGoWorkCountData,
      },
      {
        chartType: "gradient-line",
        label: "총 모집 근로자 수(명)",
        color: "primary",
        data: totalMaxRecruitmentData,
      },
    ],
  };
}

function transformData3(data3: GraphThree[]) {
  const labels = data3.map((item) => item.workDay);
  const data = data3.map((item) => (item.recruitmentRate * 100).toFixed(0));

  return {
    labels,
    datasets: [
      {
        label: "모집율(%)",
        color: "dark",
        data,
      },
    ],
  };
}
export { transformData, transformData2, transformData3 };
