/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { FormEvent, useEffect } from "react";
import Apis from "apis/remotes";
import { App, Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import { IEmploymentForm } from "apis/response";
import Password from "antd/es/input/Password";
import { handleInput } from "utils/PhoneString";

function Cover(): JSX.Element {
  const { message } = App.useApp();
  const navigate = useNavigate();

  const postCreateWork = (formData: any) => {
    // 폼 요소에서 employmentName, managerName, memo, email과 password 값을 가져오기
    Apis.postEmployment({
      ...formData,
      email: formData.gsdd_email,
      password: formData.gsdd_password,
      memo: "",
      role: "employ",
    })
      .then((resolve) => {
        message.success("가입 신청이 완료되었습니다.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
            GSDD 인력사무소 회원가입
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            구슬땀 인력사무소 가입을 환영합니다.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {/* <form onSubmit={(e) => postCreateWork(e)}> */}
          <Form onFinish={postCreateWork} layout="vertical" autoComplete="off">
            <FormItem label="인력사무소 업체명" name="employmentName">
              <Input type="text" />
            </FormItem>

            <FormItem label="인력사무소 담당자" name="managerName">
              <Input type="text" />
            </FormItem>
            <FormItem label="인력사무소 연락처" name="phone">
              <Input type="tel" maxLength={11} onInput={handleInput} />
            </FormItem>
            <FormItem label="로그인 아이디" name="gsdd_email">
              <Input type="text" />
            </FormItem>
            <FormItem label="비밀번호" name="gsdd_password">
              <Password type="password" maxLength={20} />
            </FormItem>

            {/* <MDBox mb={2}>
              <MDInput
                autoComplete="off"
                type="text"
                label="인력사무소 업체명"
                name="employmentName"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="인력사무소 담당자"
                name="managerName"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                id="your-input-id"
                autoComplete="off"
                type="email"
                label="로그인 이메일"
                name="email"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                id="your-input-password"
                autoComplete="off"
                type="password"
                label="비밀번호"
                name="password"
                variant="standard"
                fullWidth
              />
            </MDBox> */}
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                가입 신청
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                이미 계정이 있으신가요?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in/basic"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  로그인
                </MDTypography>
              </MDTypography>
            </MDBox>
          </Form>
          {/* </form> */}
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
