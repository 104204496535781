import { Typography } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";

function STcomp({
  title,
  amount,
  variant = "gradient",
  fontSize,
  color,
}: {
  title: string;
  amount: number | string;
  variant?: "gradient" | "contained";
  fontSize?: number;
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
}) {
  return (
    <MDBox display={"flex"} alignItems={"center"} gap={2}>
      <MDBadge
        color={color}
        variant={variant}
        size="lg"
        badgeContent={
          <Typography fontSize={14} fontWeight={700} color={"white"}>
            {title}
          </Typography>
        }
      />
      <Typography variant="h6" color="info" fontWeight={500} fontSize={fontSize}>
        {amount}
      </Typography>
    </MDBox>
  );
}

export default STcomp;
