import MDBox from "components/MDBox";
import { useState } from "react";
import { Form, Input, Select, Checkbox, Typography, Divider } from "antd";
import FormItem from "antd/es/form/FormItem";
import MDButton from "components/MDButton";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { handleInput, inputToPhone } from "utils/PhoneString";
import Apis from "apis/remotes";
import { IFieldEvaluation, IfieldEvaluationWork } from "apis/response";
import { Card } from "@mui/material";
import { genderString } from "utils";
import MDTypography from "components/MDTypography";
import { calculateAge } from "utils/utilDate";

const { Option } = Select;

const { Text } = Typography;

// 1단계: 핸드폰 인증 Form 수정

const Step1PhoneVerification = ({
  onSuccess,
}: {
  onSuccess: (evaluationList: IFieldEvaluation[]) => void;
}) => {
  const [form] = Form.useForm();

  const handlePhoneVerification = (formData: FormData) => {
    const { validPhone } = formData as any;

    Apis.getFieldEvaluationByPhone(validPhone)

      .then((response) => {
        // 핸드폰 인증 성공 후 공고 리스트를 response 받음

        const evaluationList = response.data.data;

        onSuccess(evaluationList); // 공고 리스트를 넘겨줌
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Form form={form} onFinish={handlePhoneVerification}>
      <MDBox mb={3}>
        <Text strong type="warning">
          평가를 진행하려면 먼저 핸드폰 인증이 필요합니다.
        </Text>
      </MDBox>

      <FormItem
        label="핸드폰 번호"
        name="validPhone"
        rules={[{ required: true, message: "핸드폰 번호를 입력하세요" }]}
      >
        <Input placeholder="핸드폰 번호를 입력하세요" onInput={handleInput} maxLength={11} />
      </FormItem>

      <FormItem>
        <MDButton fullWidth type="submit" color="info">
          인증하기
        </MDButton>
      </FormItem>
    </Form>
  );
};

// 2단계: 공고 선택 Form

const Step2AnnouncementSelection = ({
  onSelect,

  evaluationList,
}: {
  onSelect: (id: number) => void;

  evaluationList: IFieldEvaluation[];
}) => {
  const [form] = Form.useForm();

  const handleAnnouncementSelect = (values: any) => {
    if (values.announcement) {
      onSelect(values.announcement); // Pass the selected announcement ID from the form
    }
  };

  return (
    <Form form={form} onFinish={handleAnnouncementSelect}>
      <MDBox mb={2}>
        <Typography.Text strong>공고를 선택한 후 평가를 진행해주세요.</Typography.Text>
      </MDBox>

      <FormItem
        label="공고 선택"
        name="announcement"
        rules={[{ required: true, message: "공고를 선택하세요" }]}
      >
        <Select placeholder="공고를 선택하세요">
          {evaluationList.map((announcement) => (
            <Option key={announcement.workId} value={announcement.workId}>
              {announcement.title} [{announcement.workDay}]
            </Option>
          ))}
        </Select>
      </FormItem>

      <FormItem>
        <MDButton fullWidth type="submit" color="info">
          공고 평가하기
        </MDButton>
      </FormItem>
    </Form>
  );
};

// 3단계: 평가자 리스트 Form
const Step3EvaluationForm = ({
  userList,
  onEvaluationComplete,
}: {
  userList: IfieldEvaluationWork[];
  onEvaluationComplete: () => void;
}) => {
  const [form] = Form.useForm();

  const handleEvaluationSubmit = (formData: any) => {
    // Map the form data to match the API's expected format
    const formattedData = userList.map((user) => {
      // trim을 사용해 공백을 제거한 후, 값이 없거나 길이가 10자 미만이면 유효하지 않다고 판단
      const comments = formData[`comments_${user.userId}`]?.trim();
      const isValidComments = comments && comments.length >= 10;

      const result: { [key: string]: any } = {
        userId: user.userId,
        workId: user.workId, // workId는 동일하나 userId로 고유성을 유지
        workEvaluationScore: formData[`rating_${user.userId}`],
        workEvaluationReplacement: formData[`replacement_${user.userId}`] || false, // 체크 안 되어 있으면 기본값으로 false
      };
      if (isValidComments) {
        result.workEvaluationComment = comments;
      }
      if (user.originWorkId) {
        result.originWorkId = user.originWorkId;
      }

      return result;
    });

    console.log(formattedData);
    // return;
    Apis.postFieldEvaluation({ data: formattedData })
      .then((resolve) => {
        onEvaluationComplete();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Form form={form} onFinish={handleEvaluationSubmit} scrollToFirstError>
      {userList.map((user) => (
        <MDBox key={user.userId} mb={3}>
          <MDBox display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <MDBox>
              <MDTypography variant="h6" fontWeight="bold">
                {user.name}
              </MDTypography>
              <MDTypography variant="body">
                {genderString(user.gender)} | {calculateAge(user.birth)}
              </MDTypography>
              <MDTypography variant="body2" color="textSecondary">
                {inputToPhone(user.phone)}
              </MDTypography>
            </MDBox>
            <MDTypography
              variant="body2"
              color={user.status === "결근" ? "error" : "success"}
              fontWeight="medium"
            >
              {user.status}
            </MDTypography>
          </MDBox>

          <FormItem
            label="평점 (1~5점)"
            initialValue={5} // 기본값을 5점으로 설정
            name={`rating_${user.userId}`} // Use userId to differentiate form fields
            rules={[{ required: true, message: "평점을 입력하세요" }]}
          >
            <Select placeholder="점수를 선택하세요">
              <Option value={5}>5점</Option>
              <Option value={4}>4점</Option>
              <Option value={3}>3점</Option>
              <Option value={2}>2점</Option>
              <Option value={1}>1점</Option>
            </Select>
          </FormItem>

          <FormItem name={`replacement_${user.userId}`} valuePropName="checked">
            <Checkbox>신규인력 대체 요청</Checkbox>
          </FormItem>

          <FormItem
            label="특이사항"
            name={`comments_${user.userId}`} // Use userId to differentiate form fields
            rules={[
              {
                min: 10,
                message:
                  "특이사항은 최소 10자 이상 입력해주세요. 불필요한 내용은 입력하지 말아주세요.",
              },
            ]}
          >
            <Input.TextArea placeholder="특이사항이 없다면 비워두세요." maxLength={255} rows={4} />
          </FormItem>

          <Divider />
        </MDBox>
      ))}

      <FormItem>
        <MDButton type="submit" color="info" fullWidth size="large">
          평가 제출하기
        </MDButton>
      </FormItem>
    </Form>
  );
};

// 4단계: 평가 완료 메시지
const Step4CompletionMessage = () => (
  <MDBox textAlign="center">
    <h2>평가가 완료되었습니다!</h2>
  </MDBox>
);

// 메인 페이지 수정

function PerformanceEvaluation(): JSX.Element {
  const [step, setStep] = useState(1); // 현재 단계 (1, 2, 3, 4)

  const [evaluationList, setEvaluationList] = useState<IFieldEvaluation[]>([]); // 공고 리스트

  const [userList, setUserList] = useState<IfieldEvaluationWork[]>([]);

  // 핸드폰 인증 성공 시 호출, 공고 리스트 전달받음

  const handlePhoneVerificationSuccess = (evaluationList: IFieldEvaluation[]) => {
    setEvaluationList(evaluationList); // 공고 리스트 저장

    setStep(2); // 2단계로 이동
  };

  // 공고 선택 시 호출

  const handleAnnouncementSelect = (selectedAnnouncementId: number) => {
    Apis.getFieldEvaluationWorkById(selectedAnnouncementId.toString()) // 선택한 공고의 평가 리스트 받아오기

      .then((resolve) => {
        setUserList(resolve.data.data);
        setStep(3); // 3단계로 이동
      })

      .catch((error) => {
        console.log(error);
      });
  };

  // 평가 완료 시 호출

  const handleEvaluationComplete = () => {
    setStep(4); // 4단계로 이동 (평가 완료 메시지 표시)
  };

  return (
    <PageLayout>
      <MDBox m={3}>
        <Card>
          <MDBox p={2}>
            {step === 1 && <Step1PhoneVerification onSuccess={handlePhoneVerificationSuccess} />}

            {step === 2 && (
              <Step2AnnouncementSelection
                onSelect={handleAnnouncementSelect}
                evaluationList={evaluationList}
              />
            )}

            {step === 3 && (
              <Step3EvaluationForm
                userList={userList}
                onEvaluationComplete={handleEvaluationComplete}
              />
            )}

            {step === 4 && <Step4CompletionMessage />}
          </MDBox>
        </Card>
      </MDBox>
    </PageLayout>
  );
}

export default PerformanceEvaluation;
