import { Card, Divider, Grid } from "@mui/material";
import { Input, Pagination, PaginationProps, Table, Typography } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { paramsFilter } from "utils/ParamsFilter";
import { tableColumn } from "./components/data";
import { IBugs } from "apis/response";
import Apis from "apis/remotes";
import TextArea from "antd/es/input/TextArea";

function BugList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [bugList, setBugList] = useState<IBugs[]>([]);
  const [total, setTotal] = useState(0);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/bug-management/bug-list${paramsFilter({
        page,
      })}`
    );
    setCurrentPage(page);
  };

  useEffect(() => {
    Apis.getDashBoardBug({ page: currentPage, perPage: 5 })
      .then((resolve) => {
        setBugList(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3} spacing={2}>
          <Grid item xs={12} sm={2}>
            <MDButton
              LinkComponent={Link}
              to={"/bug-management/new-bug"}
              variant="gradient"
              color="info"
            >
              에러 제보
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <Card>
        <Table
          pagination={false}
          rowKey={(record) => record.adminBugId}
          scroll={{ x: "max-content" }}
          dataSource={bugList}
          columns={tableColumn}
          expandable={{
            expandedRowRender: (record) => (
              <MDBox px={6}>
                <Typography.Title level={5}>제목</Typography.Title>
                <Input value={record.title} readOnly />
                <Divider />
                <Typography.Title level={5}>내용</Typography.Title>
                <TextArea autoSize readOnly value={record.content} />
              </MDBox>
            ),
          }}
          //   style={{ cursor: "pointer" }}
          //   onRow={(record, _) => {
          //     return {
          //       onClick: () => {
          //         navigate(`/bug-management/bug-details/${record.adminBugId}`);
          //       },
          //     };
          //   }}
          footer={() => (
            <Pagination
              current={currentPage}
              onChange={handleOnChangePage}
              pageSize={5}
              total={total}
              showSizeChanger={false}
            />
          )}
        />
      </Card>
    </DashboardLayout>
  );
}
export default BugList;
