import { Grid, Typography } from "@mui/material";
import { Table } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { tableColumn } from "./components/data";

import { useParams } from "react-router-dom";
import STcomp from "components/STcomp";
import Apis from "apis/remotes";
import { INewSettlementById } from "apis/response";

function SettlementDetailsVer2(): JSX.Element {
  const { id } = useParams();

  const [settlements, setWorkList] = useState<INewSettlementById[]>([]);
  const [totalResult, setTotalResult] = useState({
    totalSettlementUserCount: 0,
    totalManDayCount: 0,
    totalPay: 0,
    averagePay: 0,
  });

  useEffect(() => {
    Apis.getSettlementById(id)
      .then((resolve) => {
        setWorkList(resolve.data.data);
        const calc = resolve.data.data;
        const totalSettlementUserCount = calc.length;
        const totalManDayCount = calc.reduce((sum, work) => sum + work.manDay, 0);
        const totalPay = calc.reduce((sum, work) => sum + work.totalAmount, 0);
        const averagePay = totalPay / totalManDayCount;
        setTotalResult({
          totalSettlementUserCount,
          totalManDayCount,
          totalPay,
          averagePay,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox flex={"flex"} flexDirection={"column"}>
          <MDBox py={1}>
            <Grid container p={1}>
              <Grid item xs={12} sm={3}>
                <Typography variant="h5">{settlements[0]?.title}</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDBox display={"flex"} justifyContent={"space-between"} width={"100%"}>
                  <STcomp
                    title={"정산인원"}
                    amount={totalResult.totalSettlementUserCount}
                    variant="contained"
                  />
                  <STcomp
                    title={"공수"}
                    amount={totalResult.totalManDayCount}
                    variant="contained"
                  />
                  <STcomp
                    title={"단가"}
                    amount={`${totalResult.averagePay.toLocaleString()}원`}
                    variant="contained"
                  />
                  <STcomp
                    title={"정산액"}
                    amount={`${totalResult.totalPay?.toLocaleString()}원`}
                    variant="contained"
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Table
              scroll={{ x: "max-content" }}
              // showHeader={false}
              pagination={false}
              rowKey={(record) => record.settlementId}
              dataSource={settlements}
              // style={{ cursor: "pointer" }}
              columns={tableColumn}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default SettlementDetailsVer2;
