import { UploadFile } from "antd";

const defaultImageList: UploadFile[] = [
  {
    uid: "done file default-1",
    name: "images/origin/work/202404/samplework1.jpg",
    status: "done",
    url: "images/origin/work/202404/samplework1.jpg",
  },
  {
    uid: "done file default-2",
    name: "images/origin/work/202404/samplework2.jpg",
    status: "done",
    url: "images/origin/work/202404/samplework2.jpg",
  },
  {
    uid: "done file default-3",
    name: "images/origin/work/202404/samplework3.jpg",
    status: "done",
    url: "images/origin/work/202404/samplework3.jpg",
  },
  {
    uid: "done file default-4",
    name: "images/origin/work/202404/samplework4.jpg",
    status: "done",
    url: "images/origin/work/202404/samplework4.jpg",
  },
  {
    uid: "done file default-5",
    name: "images/origin/work/202404/samplework5.jpg",
    status: "done",
    url: "images/origin/work/202404/samplework5.jpg",
  },
];

export { defaultImageList };
