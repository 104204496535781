import { Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { SettlementData } from "apis/response";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";

const tableColumn: ColumnsType<SettlementData> = [
  {
    title: "현장명",
    dataIndex: "title",
    key: "title",
    width: "25%",
    ellipsis: true,
    render: (title: string) => (
      <Typography
        variant="h5"
        fontWeight={700}
        style={{
          maxWidth: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
    ),
  },

  {
    title: "정산인원",
    dataIndex: "settlementUserCount",
    key: "settlementUserCount",
    ellipsis: true,
    render: (settlementUserCount: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          variant="contained"
          size="lg"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              정산인원
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {settlementUserCount?.toLocaleString()}
        </Typography>
      </MDBox>
    ),
  },

  {
    title: "공수",
    dataIndex: "manDayCount",
    key: "manDayCount",
    ellipsis: true,
    render: (manDayCount: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          variant="contained"
          size="lg"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              공수
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {manDayCount?.toLocaleString()}
        </Typography>
      </MDBox>
    ),
  },
  {
    title: "단가",
    dataIndex: "manDayAmount",
    key: "manDayAmount",
    ellipsis: true,
    render: (manDayAmount: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          variant="contained"
          size="lg"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              단가
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {manDayAmount?.toLocaleString()}원
        </Typography>
      </MDBox>
    ),
  },
  {
    title: "정산액",
    dataIndex: "totalAmount",
    key: "totalAmount",
    ellipsis: true,
    render: (totalAmount: number, record) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          variant="contained"
          size="lg"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              정산액
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {totalAmount?.toLocaleString()}원
        </Typography>
      </MDBox>
    ),
  },
];
export { tableColumn };
