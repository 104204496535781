import { Divider, Grid } from "@mui/material";
import Card from "@mui/material/Card";

import { App, Select, message } from "antd";
import Form, { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import Input from "antd/es/input/Input";
import Password from "antd/es/input/Password";
import Apis from "apis/remotes";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { handleInput } from "utils/PhoneString";

function MyProfile(): JSX.Element {
  const navigate = useNavigate();
  const { message, notification } = App.useApp();
  const [form] = useForm();
  const [passwordForm] = useForm();
  const [loading, setLoading] = useState(false);

  const handleUpdateMyProfile = (e: FormData) => {
    console.log(e);
    Apis.putEmployment(e)
      .then((resolve) => {
        message.success("Success");
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message ?? "",
        });
      });
  };

  const handleLogOut = () => {
    Apis.deleteAuth()
      .then((resolve) => {
        message.info("로그아웃 되었습니다.");
        navigate("/authentication/sign-in/basic", { replace: true });
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message ?? "",
        });
      });
  };

  const handleUpdatePasswordForm = async (formData: FormData) => {
    try {
      setLoading(true);
      console.log(formData);
      await Apis.putEmploymentPasswordChange(formData)
        .then((resolve) => {
          message.success("비밀번호가 변경되었습니다.");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      passwordForm.resetFields();
      setLoading(false);
    }
  };
  useEffect(() => {
    Apis.getEmploymentMy()
      .then((resolve) => {
        console.log(resolve.data);
        form.setFieldsValue({
          ...resolve.data,
        });
        //       employmentAgencyId: number;
        // employmentName: string;
        // managerName: string;
        // phone: string;
        // memo: string;
        // role: string;
        // email: string;
        // status: string;
        // isDeleted: boolean;
        // createdAt: string;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Form
              form={form}
              onFinish={handleUpdateMyProfile}
              labelCol={{ sm: { span: 6 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
              style={{ maxWidth: 600 }}
            >
              <FormItem
                label="업체명"
                name="employmentName"
                rules={[
                  {
                    required: true,
                    message: "업체명을 입력하세요.",
                  },
                ]}
              >
                <Input type="text" maxLength={20} showCount />
              </FormItem>
              <FormItem
                label="담당자"
                name="managerName"
                rules={[
                  {
                    required: true,
                    message: "담당자를 입력하세요.",
                  },
                ]}
              >
                <Input type="text" maxLength={20} showCount />
              </FormItem>
              <FormItem
                label="연락처"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "연락처를 입력하세요.",
                  },
                ]}
              >
                <Input
                  type="tel"
                  // pattern="[0-9]{2-3}-[0-9]{4}-[0-9]{4}"
                  placeholder="01012345678"
                  onInput={handleInput}
                />
              </FormItem>
              <FormItem
                label="아이디"
                name="email"
                hidden
                rules={[
                  {
                    required: true,
                    message: "아이디를 입력하세요.",
                  },
                ]}
              >
                <Input type="text" autoComplete="off" />
              </FormItem>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <MDButton variant="gradient" color="info" type="submit">
                    내정보 변경
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
            <Divider textAlign="center" />
            <Form
              labelCol={{ sm: { span: 6 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
              style={{ maxWidth: 600 }}
              form={passwordForm}
              onFinish={handleUpdatePasswordForm}
            >
              <FormItem
                label="새 비밀번호"
                name="newPassword"
                rules={[
                  { required: true, message: "새 비밀번호를 입력하세요." },
                  { min: 8, message: "비밀번호는 최소 8자리입니다." },
                  { max: 16, message: "비밀번호는 최대 16자리입니다." },
                  {
                    pattern: /^(?=.*[!@#$%^&*])(?=.*[0-9])/,
                    message: "비밀번호는 특수문자와 숫자를 포함해야 합니다.",
                  },
                ]}
              >
                <Password max={16} />
              </FormItem>
              <FormItem
                label="새 비밀번호 확인"
                name="newPasswordCheck"
                rules={[
                  { required: true, message: "새 비밀번호를 입력하세요." },
                  { min: 8, message: "비밀번호는 최소 8자리입니다." },
                  { max: 16, message: "비밀번호는 최대 16자리입니다." },
                  {
                    pattern: /^(?=.*[!@#$%^&*])(?=.*[0-9])/,
                    message: "비밀번호는 특수문자와 숫자를 포함해야 합니다.",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("비밀번호가 일치하지 않습니다."));
                    },
                  }),
                ]}
              >
                <Password max={16} />
              </FormItem>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <MDButton variant="gradient" color="dark" type="submit" disabled={loading}>
                    비밀번호 변경
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
        <MDBox my={3} style={{ float: "right" }}>
          <MDButton variant="gradient" color="dark" onClick={() => handleLogOut()}>
            로그아웃
          </MDButton>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default MyProfile;
