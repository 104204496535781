import { Grid } from "@mui/material";
import { Pagination, PaginationProps, Table } from "antd";
import Apis from "apis/remotes";
import MDBox from "components/MDBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { useEffect, useState } from "react";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import Groups2Icon from "@mui/icons-material/Groups2";
import VoiceOverOffIcon from "@mui/icons-material/VoiceOverOff";
import { tableColumn } from "../data";

function TodayDashBoard(): JSX.Element {
  const [currentPage, setCurrentPage] = useState(1);
  const [today, setToday] = useState([]);
  const [result, setResult] = useState<{
    totalMaxRecruitment: number;
    totalWorkApplyCount: number;
    totalCurrentRecruitment: number;
    totalGoWorkCount: number;
    totalWorkAbsenceCount: number;
    totalUnaccountedCount: number;
  }>({
    totalMaxRecruitment: 0,
    totalWorkApplyCount: 0,
    totalCurrentRecruitment: 0,
    totalGoWorkCount: 0,
    totalWorkAbsenceCount: 0,
    totalUnaccountedCount: 0,
  });
  const [total, setTotal] = useState(0);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    Apis.getDashBoardToday({ page: currentPage, perPage: 10 })
      .then((resolve) => {
        setToday(resolve.data.data);
        setTotal(resolve.data.total);
        setResult({
          totalMaxRecruitment: resolve.data.totalMaxRecruitment,
          totalWorkApplyCount: resolve.data.totalWorkApplyCount,
          totalCurrentRecruitment: resolve.data.totalCurrentRecruitment,
          totalGoWorkCount: resolve.data.totalGoWorkCount,
          totalWorkAbsenceCount: resolve.data.totalWorkAbsenceCount,
          totalUnaccountedCount: resolve.data.totalUnaccountedCount,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);
  return (
    <MDBox>
      <MDBox mt={2}>
        <Grid container spacing={2}>
          {/* 등록 공고 수 */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <DefaultInfoCard
              icon={<HomeWorkIcon fontSize="large" />}
              title="등록 공고"
              //description="Freelance Payment"
              value={`${(total ?? 0).toLocaleString()}`}
            />
          </Grid>
          {/* 총 모집 근로자 수 */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <DefaultInfoCard
              icon={<Groups2Icon fontSize="large" />}
              title="모집 근로자"
              //description="Freelance Payment"
              value={`${(result.totalMaxRecruitment ?? 0).toLocaleString()}`}
            />
          </Grid>
          {/* 총 지원자 수 */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <DefaultInfoCard
              icon={<GroupAddIcon fontSize="large" />}
              title="지원자"
              //description="Freelance Payment"
              value={`${(result.totalWorkApplyCount ?? 0).toLocaleString()}`}
            />
          </Grid>
          {/* 총 출근 승인 인원 */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <DefaultInfoCard
              icon={<PersonPinIcon fontSize="large" />}
              title="지원승인 인원"
              //description="Freelance Payment"
              value={`${(result.totalCurrentRecruitment ?? 0).toLocaleString()}`}
            />
          </Grid>
          {/* 총 출근자 수 */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <DefaultInfoCard
              icon={<PersonAddAlt1Icon fontSize="large" />}
              title="출근자"
              //description="Freelance Payment"
              value={`${(result.totalGoWorkCount ?? 0).toLocaleString()}`}
            />
          </Grid>
          {/* 총 결근자 수 */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <DefaultInfoCard
              icon={<PersonOffIcon fontSize="large" />}
              title="결근자"
              //description="Freelance Payment"
              value={`${(result.totalWorkAbsenceCount ?? 0).toLocaleString()}`}
            />
          </Grid>
          {/* 총 출근 미확인자 수 */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <DefaultInfoCard
              icon={<VoiceOverOffIcon fontSize="large" />}
              title="출근 미확인자"
              //description="Freelance Payment"
              value={`${(result.totalUnaccountedCount ?? 0).toLocaleString()}`}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <Table
          rowKey={(record) => record.workId}
          scroll={{ x: "max-content" }}
          pagination={false}
          showHeader={false}
          dataSource={today}
          columns={tableColumn}
          footer={() => (
            <Pagination
              current={currentPage}
              onChange={handleOnChangePage}
              pageSize={10}
              total={total}
              showSizeChanger={false}
            />
          )}
        />
      </MDBox>
    </MDBox>
  );
}
export default TodayDashBoard;
