import { Grid, Typography } from "@mui/material";
import { DatePicker, Form, Pagination, PaginationProps, Table } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { tableColumn } from "./components/data";

import { useLocation, useNavigate } from "react-router-dom";
import { INewSettlement } from "apis/response";
import dayjs, { Dayjs } from "dayjs";
import { paramsFilter } from "utils/ParamsFilter";
import STcomp from "components/STcomp";
import MDButton from "components/MDButton";
import Apis from "apis/remotes";
import FormItem from "antd/es/form/FormItem";
import { useForm } from "antd/es/form/Form";
type IFilterSearch = "week" | "month" | "3month" | "year";
const disabledDate = (current: any) => {
  // 2024년 1월 이전
  if (current < dayjs("2023-01-01")) {
    return true;
  }
  // 오늘 이후
  if (current > Date.now()) {
    return true;
  }
  return false;
};
function SettlementListVer2(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const searchStart =
    params.get("searchStart") ?? dayjs(new Date()).subtract(1, "week").format("YYYY-MM-DD");
  const searchEnd = params.get("searchEnd") ?? dayjs(new Date()).format("YYYY-MM-DD");
  const page = params.get("page");
  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(0);
  const [settlements, setSettlements] = useState<INewSettlement[]>([]);
  const [totalResult, setTotalResult] = useState({
    totalSettlementUserCount: 0,
    totalManDayCount: 0,
    totalTotalAmount: 0,
    averagePay: 0,
  });
  // const [total, setTotal] = useState(0);
  const [form] = useForm();
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/settlement-management/settlement-history-ver${paramsFilter({
        page,
        searchStart,
        searchEnd,
      })}`
    );
  };
  /** 날짜 필터 클릭 */
  const handleonChangeSearchRange = (filter: IFilterSearch) => {
    const searchEnd = dayjs(new Date()).format("YYYY-MM-DD");
    let searchStart;
    switch (filter) {
      case "week":
        searchStart = dayjs(searchEnd).subtract(1, "week").format("YYYY-MM-DD");
        break;
      case "month":
        searchStart = dayjs(searchEnd).subtract(1, "month").format("YYYY-MM-DD");
        break;
      case "3month":
        searchStart = dayjs(searchEnd).subtract(3, "month").format("YYYY-MM-DD");
        break;
      case "year":
        searchStart = dayjs(searchEnd).subtract(1, "year").format("YYYY-MM-DD");
        break;
      default:
        searchStart = dayjs(searchEnd).subtract(1, "week").format("YYYY-MM-DD");
    }
    setCurrentPage(1);
    navigate(
      `/settlement-management/settlement-history-ver${paramsFilter({
        page: 1,
        searchStart,
        searchEnd,
      })}`
    );
  };

  /** 사용자 커스텀 조회 */
  const onFinish = (e: any) => {
    const { startEnd } = e;

    const searchStart = dayjs(startEnd[0]).format("YYYY-MM-DD");
    const searchEnd = dayjs(startEnd[1]).format("YYYY-MM-DD");
    setCurrentPage(1);
    navigate(
      `/settlement-management/settlement-history-ver${paramsFilter({
        page: 1,
        searchStart,
        searchEnd,
      })}`
    );
  };
  /** 자동 조회 */
  const onFinish2 = (e: [Dayjs, Dayjs]) => {
    if (e === null) return;
    const startEnd = e;
    const searchStart = dayjs(startEnd[0]).format("YYYY-MM-DD");
    const searchEnd = dayjs(startEnd[1]).format("YYYY-MM-DD");
    setCurrentPage(1);
    navigate(
      `/settlement-management/settlement-history-ver${paramsFilter({
        page: 1,
        searchStart,
        searchEnd,
      })}`
    );
  };

  function renderDepao(work: INewSettlement) {
    const totalSettlementUserCount = work.settlementData.reduce(
      (sum, work) => sum + work.settlementUserCount,
      0
    );
    const totalManDayCount = work.settlementData.reduce((sum, work) => sum + work.manDayCount, 0);
    const totalPayAmount = work.settlementData.reduce((sum, work) => sum + work.totalAmount, 0);
    const averagePayAmount = isNaN(totalPayAmount / totalManDayCount)
      ? 0
      : totalPayAmount / totalManDayCount;
    return (
      <MDBox py={1}>
        <MDBox p={1}>
          <Typography variant="h5">{work.workDay}</Typography>
        </MDBox>
        <Table
          scroll={{ x: "max-content" }}
          showHeader={false}
          pagination={false}
          rowKey={(record) => record.workId}
          dataSource={work.settlementData}
          style={{ cursor: "pointer" }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(`/settlement-management/settlement-history-ver-detail/${record.workId}`);
              },
            };
          }}
          columns={tableColumn}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <STcomp
                    color="secondary"
                    variant="contained"
                    title={"정산 인원"}
                    amount={totalSettlementUserCount}
                  />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <STcomp
                    color="secondary"
                    variant="contained"
                    title={"공수"}
                    amount={totalManDayCount}
                  />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <STcomp
                    color="secondary"
                    variant="contained"
                    title={"평균단가"}
                    amount={`${averagePayAmount?.toLocaleString()}원`}
                  />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <STcomp
                    color="secondary"
                    variant="contained"
                    title={"총 정산액"}
                    amount={`${totalPayAmount?.toLocaleString()}원`}
                  />
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </MDBox>
    );
  }

  useEffect(() => {
    form.setFieldsValue({ startEnd: [dayjs(searchStart), dayjs(searchEnd)] });
    Apis.getSettlement({
      page: currentPage,
      perPage: 5,
      searchStart,
      searchEnd,
    })
      .then((resolve) => {
        setSettlements(resolve.data.data);
        setTotal(resolve.data.total);

        let totalSettlementUserCount = resolve.data.totalSettlementUserCount;
        let totalManDayCount = resolve.data.totalManDayCount;
        let totalTotalAmount = resolve.data.totalTotalAmount;
        let averagePay = 0;

        averagePay = isNaN(totalTotalAmount / totalManDayCount)
          ? 0
          : totalTotalAmount / totalManDayCount;
        setTotalResult({
          totalSettlementUserCount,
          totalManDayCount,
          totalTotalAmount,
          averagePay,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, searchStart, searchEnd]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3} spacing={1}>
          <Grid item sm={12} md={6}>
            <Form form={form} onFinish={onFinish}>
              <Grid item container>
                <Grid item xs={4}>
                  <MDButton color="info" type="submit">
                    기간조회
                  </MDButton>
                </Grid>
                <Grid item xs={8}>
                  <FormItem name="startEnd">
                    <DatePicker.RangePicker disabledDate={disabledDate} onChange={onFinish2} />
                  </FormItem>
                </Grid>
              </Grid>
            </Form>
          </Grid>
          <Grid item sm={12} md={6}>
            <MDBox display={"flex"} gap={1}>
              <MDButton size="small" color="info" onClick={() => handleonChangeSearchRange("week")}>
                최근 일주일
              </MDButton>
              <MDButton
                size="small"
                color="info"
                onClick={() => handleonChangeSearchRange("month")}
              >
                최근 1달
              </MDButton>
              <MDButton
                size="small"
                color="info"
                onClick={() => handleonChangeSearchRange("3month")}
              >
                3개월 이내
              </MDButton>
              <MDButton size="small" color="info" onClick={() => handleonChangeSearchRange("year")}>
                최근 1년
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} md={2}>
            <STcomp
              title={"총 정산 인원"}
              amount={totalResult.totalSettlementUserCount}
              color="secondary"
              variant="contained"
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <STcomp
              title={"총 공수"}
              amount={totalResult.totalManDayCount}
              color="secondary"
              variant="contained"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <STcomp
              title={"평균 단가"}
              amount={`${totalResult.averagePay?.toLocaleString()}원`}
              color="secondary"
              variant="contained"
            />
          </Grid>
          <Grid item xs={6} md={5}>
            <STcomp
              title={"총 정산액"}
              amount={`${totalResult.totalTotalAmount?.toLocaleString()}원`}
              color="secondary"
              variant="contained"
            />
          </Grid>
        </Grid>

        <MDBox flex={"flex"} flexDirection={"column"}>
          {settlements.map((work, index) => {
            return <MDBox key={`depao-table-${index}`}>{renderDepao(work)}</MDBox>;
          })}
        </MDBox>
        <MDBox my={2}>
          <Pagination
            current={currentPage}
            onChange={handleOnChangePage}
            pageSize={5}
            total={total}
            showSizeChanger={false}
          />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default SettlementListVer2;
