import { Card, Icon } from "@mui/material";
import { Switch, Table } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { ColumnsType } from "antd/es/table";

import { IWorkPartnerListById } from "apis/response";
import Apis from "apis/remotes";
import { calculateAge } from "utils/utilDate";
import RenderUserScore from "components/RenderUserScore";

function PartnerDetails(): JSX.Element {
  const { id } = useParams();
  const [workPartners, setWorkPartners] = useState<IWorkPartnerListById[]>([]);

  const handleUpdateWorkPartnerListOnOff = (worker: IWorkPartnerListById) => {
    Apis.putWorkPartnerListOnOff({
      userId: worker.userId,
      workId: worker.workId,
      isPartner: !worker.isPartner,
    })
      .then((resolve) => {
        setWorkPartners((prev) => {
          return prev.map((item) => {
            if (item.userId === worker.userId) {
              return { ...item, isPartner: !worker.isPartner };
            } else {
              return item;
            }
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const tableColumn: ColumnsType<IWorkPartnerListById> = [
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "평점",
      dataIndex: "userScore",
      key: "userScore",
      ellipsis: true,
      align: "center",
      render: RenderUserScore,
    },
    {
      title: "나이",
      dataIndex: "birth",
      key: "birth",
      ellipsis: true,
      render: (birth) => calculateAge(birth),
    },
    {
      title: (
        <p>
          최근 10회 <br />
          근무 결근 횟수
        </p>
      ),
      align: "center",
      dataIndex: "recentAbsenceCount",
      key: "recentAbsenceCount",
      ellipsis: true,
    },
    {
      title: "혈압",
      dataIndex: "bloodPressure",
      key: "bloodPressure",
      align: "center",
      ellipsis: true,
    },
    {
      title: "연락처",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
    },
    {
      title: "파트너 설정",
      dataIndex: "isPartner",
      key: "isPartner",
      ellipsis: true,
      align: "center",
      render: (isPartner: boolean, record) => {
        return (
          <Switch
            defaultValue={isPartner}
            checkedChildren={"파트너"}
            unCheckedChildren={"해제"}
            onChange={() => {
              handleUpdateWorkPartnerListOnOff(record);
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    Apis.getWorkPartnerListById(id)
      .then((resolve) => {
        setWorkPartners(resolve.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          {/* <MDBox p={3}>
            <MDTypography fontWeight="bold" textTransform="capitalize" variant="h4" noWrap>
              송도 국제도시 현장
            </MDTypography>
          </MDBox> */}
          <Table
            pagination={false}
            rowKey={(record) => record.userId}
            scroll={{ x: "max-content" }}
            dataSource={workPartners}
            columns={tableColumn}
            // style={{ cursor: "pointer" }}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default PartnerDetails;
