/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App as AntdApp, ConfigProvider } from "antd";
import App from "App";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/ko";
import locale from "antd/locale/ko_KR";

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from "context";

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko"> */}
    <ConfigProvider locale={locale}>
      <MaterialUIControllerProvider>
        <AntdApp>
          <App />
        </AntdApp>
      </MaterialUIControllerProvider>
    </ConfigProvider>
    {/* </LocalizationProvider> */}
  </BrowserRouter>
);
