import dayjs from "dayjs";

/**  */
function convertDateToYYYYMMDD(date: any): string | null {
  try {
    // date가 Date 객체인지 확인
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return null;
    }

    // YYYYMMDD 형식으로 변환
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return year + month + day;
  } catch (error) {
    return null;
  }
}
function convertDateToYYYYDotMMDotDD(date: any): string | null {
  try {
    // date가 Date 객체인지 확인
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return null;
    }

    // YYYYMMDD 형식으로 변환
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return year + "." + month + "." + day;
  } catch (error) {
    return null;
  }
}
function calculateWorkHours(workDateAndTime: any) {
  const [startTime, endTime] = workDateAndTime;
  const diffInMilliseconds = endTime - startTime;
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  return diffInHours;
}
function formatWorkDateAndTime(workDateAndTime: any) {
  const [startTime, endTime] = workDateAndTime;
  const formatOptions = [
    { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" },
    { month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" },
    { day: "2-digit", hour: "2-digit", minute: "2-digit" },
    { hour: "2-digit", minute: "2-digit" },
  ];

  let format;

  if (startTime.getFullYear() !== endTime.getFullYear()) {
    format = formatOptions[0];
  } else if (startTime.getMonth() !== endTime.getMonth()) {
    format = formatOptions[1];
  } else if (startTime.getDate() !== endTime.getDate()) {
    format = formatOptions[2];
  } else {
    format = formatOptions[3];
  }

  const startFormatted = startTime.toLocaleString("ko-KR", format);
  const endFormatted = endTime.toLocaleString("ko-KR", format);

  return `${startFormatted} ~ ${endFormatted}`;
}

function formatWorkHours(workDateAndTime: any) {
  // 출근 시간과 퇴근 시간 추출

  // 출근 시간을 "HH:mm" 형식으로 변환
  const formattedStartTime = workDateAndTime.toLocaleTimeString("ko-KR", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  // 퇴근 시간을 "HH:mm" 형식으로 변환

  // 변환된 출근 시간과 퇴근 시간 반환
  return formattedStartTime;
}

/** 나이 계산기 */
function calculateAge(birthdate: string) {
  try {
    // 입력된 생년월일을 Date 객체로 변환합니다.
    const birthDate = new Date(birthdate);
    const today = new Date();

    // 나이 계산
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    // 오늘 날짜가 생일 이전이라면 나이에서 1을 빼줍니다.
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return isNaN(age) ? "미입력" : age + "세";
  } catch (error) {
    return "미입력";
  }
}

const disabledDate = (current: any) => {
  // can not select days before tomorrow
  return current && current < dayjs().endOf("day");
};

// interface DisabledTimes {
//   disabledHours: () => number[];
//   disabledMinutes?: () => number[];
//   disabledSeconds?: () => number[];
// }

// const disabledTime = (date: dayjs.Dayjs, range?: "start" | "end"): DisabledTimes => {
//   const disabledHours = () => {
//     const hours = [];
//     // 오전 6시부터 오후 8시까지를 제외한 모든 시간 비활성화
//     for (let i = 0; i < 24; i++) {
//       if (i < 6 || i > 20) {
//         hours.push(i);
//       }
//     }
//     return hours;
//   };

//   return {
//     disabledHours,
//   };
// };

// const disabledStartTime = (date: dayjs.Dayjs, range?: "start" | "end"): DisabledTimes => {
//   const disabledHours = () => {
//     const hours = [];
//     // 오후 17시부터 오후 23까지를 제외한 모든 시간 비활성화
//     for (let i = 0; i < 24; i++) {
//       if (i < 17 || i > 24) {
//         hours.push(i);
//       }
//     }
//     return hours;
//   };

//   return {
//     disabledHours,
//   };
// };

// const disabledEndTime = (date: dayjs.Dayjs, range?: "start" | "end"): DisabledTimes => {
//   const disabledHours = () => {
//     const hours = [];
//     // 오전 3시부터 오전 5시까지를 제외한 모든 시간 비활성화
//     for (let i = 0; i < 24; i++) {
//       if (i < 3 || i > 5) {
//         hours.push(i);
//       }
//     }
//     return hours;
//   };

//   return {
//     disabledHours,
//   };
// };

/** 현재 시간 기준으로 출근확인 클릭가능한지 체크 */
function checkAttendanceDisable() {
  // 현재 날짜와 시간을 가져옴
  const now = new Date();

  // 현재 시간의 시와 분을 가져옴
  const hours = now.getHours();

  // 오전 5시 이전인지 검사
  if (hours < 5) {
    return true; // 5시 이전이면 true 반환
  } else {
    return false; // 그 외의 경우 false 반환
  }
}
/** 현재 시간 기준으로 퇴근확인 클릭가능한지 체크 */
function checkDepartureDisable() {
  // 현재 날짜와 시간을 가져옴
  const now = new Date();

  // 현재 시간의 시를 가져옴
  const hours = now.getHours();

  // 오후 4시 이후에만 false 반환
  if (hours >= 16) {
    return false; // 오후 4시 이후이면 false 반환 (클릭 가능)
  } else {
    return true; // 그 외의 경우 true 반환 (클릭 불가능)
  }
}

/** 현재 시간 기준으로 결근처리 클릭가능한지 체크 */
// 오전 6~오후 5시에만 누를 수 있게
function checkAbsenceDisable() {
  // 현재 날짜와 시간을 가져옴
  const now = new Date();

  // 현재 시간의 시를 가져옴
  const hours = now.getHours();

  // 오전 6시 (6) 이상이고 오후 5시 (17) 미만이면 클릭 가능 (false 반환)
  if (hours >= 6 && hours < 17) {
    return false; // 클릭 가능
  } else {
    return true; // 클릭 불가능
  }
}

export {
  convertDateToYYYYMMDD,
  convertDateToYYYYDotMMDotDD,
  calculateWorkHours,
  formatWorkDateAndTime,
  formatWorkHours,
  calculateAge,
  disabledDate,
  checkAttendanceDisable,
  checkDepartureDisable,
  checkAbsenceDisable,
};
