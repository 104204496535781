import { ColumnsType } from "antd/es/table";
import { IWorkPartnerList } from "apis/response";

const tableColumn: ColumnsType<IWorkPartnerList> = [
  {
    title: "현장명",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
  },
  {
    title: "본사",
    dataIndex: "company",
    key: "company",
    ellipsis: true,
  },
  {
    title: "인력사무소",
    dataIndex: "workCreatorName",
    key: "workCreatorName",
    ellipsis: true,
  },
  {
    title: "활성 파트너(명)",
    dataIndex: "currentPartner",
    key: "currentPartner",
    align: "center",
    ellipsis: true,
  },
  {
    title: "전체 파트너(명)",
    dataIndex: "totalPartner",
    key: "totalPartner",
    align: "center",
    ellipsis: true,
  },
];

export { tableColumn };
