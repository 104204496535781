import React, { useEffect, useState } from "react";
import { InputNumber, Table, message } from "antd";
import Apis from "apis/remotes";
import { IMatchCurrentApplyFive } from "apis/response";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { calculateAge } from "utils/utilDate";
import StarIcon from "@mui/icons-material/Star";
import RenderUserScore from "components/RenderUserScore";
import { inputToPhone } from "utils/PhoneString";

interface Props {
  workId: string | number;
}
/** 정산 컴포넌트 */
function PaymentExample({ workId }: Props): JSX.Element {
  const [data, setData] = useState<IMatchCurrentApplyFive[]>([]);
  const [editingKey, setEditingKey] = useState(0);
  const [editTarget, setEditTarget] = useState<IMatchCurrentApplyFive>();

  const isEditing = (record: IMatchCurrentApplyFive) => record.settlementId === editingKey;

  const edit = (record: IMatchCurrentApplyFive) => {
    const recordKey: Partial<IMatchCurrentApplyFive> & { settlementId: number } = record;
    console.log(record);
    setEditTarget(record);
    setEditingKey(recordKey.settlementId);
  };

  const cancel = () => {
    const newData = [...data];
    const index = newData.findIndex((item) => editTarget.settlementId === item.settlementId);
    if (index > -1) {
      const item = newData[index];
      console.log("@@ item", item);
      newData.splice(index, 1, {
        ...editTarget,
      });

      setData(newData);

      setEditingKey(0);
    }
  };

  const save = async (key: React.Key) => {
    try {
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.settlementId);
      if (index > -1) {
        const item = newData[index];
        console.log("@@ item", item);
        newData.splice(index, 1, {
          ...item,
        });

        // setData(newData);
        setEditingKey(0);
        // API 호출 부분 추가
        const manDayAndTotalPay = item;
        console.log(manDayAndTotalPay);
        Apis.putSettlementById(key, {
          manDay: manDayAndTotalPay.manDay,
          totalAmount: manDayAndTotalPay.totalAmount,
        })
          .then((resolve) => {
            message.success("수정 완료");
            // setData((prev) =>
            //   prev.map((settlement) => {
            //     if (settlement.settlementId === key) {
            //       return { ...settlement, status: "입금완료" };
            //     } else {
            //       return settlement;
            //     }
            //   })
            // );
          })
          .catch((error) => console.log(error));
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleQuantityChange = (value: number | undefined, key: React.Key) => {
    console.log("@@ value:", value, " @@ key:", key);
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.settlementId);
    if (index > -1) {
      const item = newData[index];
      const manDay = value || 0;
      const totalAmount = manDay * item.manDayAmount;
      newData.splice(index, 1, {
        ...item,
        manDay,
        totalAmount,
      });
      console.log("@@ newData: ", newData);
      setData(newData);
    }
  };

  const handlePaidAmountChange = (value: number | undefined, key: React.Key) => {
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.settlementId);
    if (index > -1) {
      const item = newData[index];
      const totalAmount = value || 0;
      const manDay = totalAmount / item.manDayAmount;
      newData.splice(index, 1, {
        ...item,
        manDay,
        totalAmount,
      });
      setData(newData);
    }
  };
  /** 입금완료 버튼 클릭 */
  const handleSettlementDeposit = (settlementId: string | number) => {
    const manDayAndTotalPay = data.find((item) => item.settlementId === settlementId);
    Apis.putSettlementDepositById(settlementId)
      .then((resolve) => {
        message.success("입금완료");
        setData((prev) =>
          prev.map((settlement) => {
            if (settlement.settlementId === settlementId) {
              return { ...settlement, status: "입금완료" };
            } else {
              return settlement;
            }
          })
        );
      })
      .catch((error) => console.log(error));
  };
  const columns: any = [
    // {
    //   title: "이력사항/연락처",
    //   dataIndex: "name", // 이 필드는 실제 사용되지 않지만, render 함수에 데이터를 전달하기 위해 필요합니다.
    //   key: "name",
    //   render: (name: string, record: any) => {
    //     const gender = record.gender === "male" ? "남" : record.gender === "female" ? "여" : "기타";
    //     return `${name}(${gender}, ${record.birth}) ${record.phone}`;
    //   },
    // },
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      align: "center",
      render: (name: string, record: any) => (
        <MDBox textAlign={"center"}>
          {record.isDeleted ? (
            <p style={{ color: "red", fontSize: 12 }}>탈퇴회원</p>
          ) : record.isPartner === true ? (
            <p style={{ color: "orange", fontSize: 12 }}>파트너</p>
          ) : null}
          {/* {record.isPartner === true ? (
            <p style={{ color: "orange", fontSize: 12 }}>파트너</p>
          ) : null} */}
          <span>{name}</span>
        </MDBox>
      ),
    },
    {
      title: "평점",
      dataIndex: "userScore",
      key: "userScore",
      render: RenderUserScore,
    },

    {
      title: "나이",
      dataIndex: "birth",
      key: "birth",
      render: (birth: string) => calculateAge(birth ?? "000513"),
    },
    {
      title: "연락처",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
      render: (phone: string) => inputToPhone(phone),
    },
    {
      title: "계좌번호",
      dataIndex: "bankAccount", // 이 필드는 실제 사용되지 않지만, render 함수에 데이터를 전달하기 위해 필요합니다.
      key: "bankAccount",
    },
    {
      title: "은행명",
      dataIndex: "bankName", // 이 필드는 실제 사용되지 않지만, render 함수에 데이터를 전달하기 위해 필요합니다.
      key: "bankName",
    },
    {
      title: "예금주",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "공수",
      dataIndex: "manDay",
      editable: true,

      render: (_: any, record: IMatchCurrentApplyFive) => {
        const editable = isEditing(record);
        return editable ? (
          <InputNumber
            value={record.manDay}
            onChange={(value) => handleQuantityChange(value, record.settlementId)}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {record.manDay}
          </div>
        );
      },
    },
    {
      title: "단가",
      dataIndex: "manDayAmount",

      render: (manDayAmount: number) => manDayAmount.toLocaleString(),
    },
    {
      title: "입금액",
      dataIndex: "paidAmount",
      editable: true,

      render: (_: any, record: IMatchCurrentApplyFive) => {
        const editable = isEditing(record);
        return editable ? (
          <InputNumber
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value: string) => value!.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => handlePaidAmountChange(value, record.settlementId)}
            value={record.totalAmount}
            style={{ minWidth: "200px" }}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {record.totalAmount?.toLocaleString()}
          </div>
        );
      },
    },
    // {
    //   title: "입금상태",
    //   dataIndex: "status",
    // },
    {
      title: "",
      dataIndex: "operation",

      render: (_: any, record: IMatchCurrentApplyFive) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <MDButton
              color="info"
              size="small"
              onClick={() => save(record.settlementId)}
              style={{ marginRight: 8 }}
            >
              수정 완료
            </MDButton>
            <MDButton size="small" color="dark" onClick={() => cancel()}>
              취소
            </MDButton>
          </span>
        ) : (
          <MDBox display={"flex"} gap={2} alignItems={"center"}>
            <MDButton
              size="small"
              color="success"
              disabled={editingKey !== 0}
              onClick={() => edit(record)}
            >
              수정
            </MDButton>

            <MDButton
              size="small"
              color="info"
              disabled={editingKey !== 0 || record.status === "입금완료"}
              onClick={() => handleSettlementDeposit(record.settlementId)}
            >
              입금 확인
            </MDButton>
          </MDBox>
        );
      },
    },
  ];

  useEffect(() => {
    Apis.getMatchCurrentApplyFive(workId)
      .then((resolve) => {
        setData(resolve.data.data);
      })
      .catch((error) => {
        message.error(error.response.data.message ?? "");
      });
  }, [workId]);
  return (
    <MDBox>
      <MDTypography variant="h5">입금</MDTypography>
      <MDBox mt={3}>
        <Table
          rowKey={(record) => record.settlementId}
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          scroll={{ x: "max-content" }}
          // pagination={{
          //   onChange: cancel,
          // }}
          pagination={false}
        />
      </MDBox>
    </MDBox>
  );
}

export default PaymentExample;
