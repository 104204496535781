import { Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { WorkData } from "apis/response";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";

const tableColumn: ColumnsType<WorkData> = [
  {
    title: "현장명",
    dataIndex: "title",
    key: "title",
    width: "25%",
    ellipsis: true,
    render: (title: string) => (
      <Typography
        variant="h5"
        fontWeight={700}
        style={{
          maxWidth: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
    ),
  },
  {
    title: "공고 이동",
    dataIndex: "workId",
    key: "workId",
    ellipsis: true,
    render: (workId) => (
      <Link to={`/announcement-management/site-details/${workId}`}>공고로 이동</Link>
    ),
  },
  {
    title: "모집인원",
    dataIndex: "maxRecruitment",
    key: "maxRecruitment",
    ellipsis: true,
    render: (maxRecruitment: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              모집인원
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {maxRecruitment}
        </Typography>
      </MDBox>
    ),
  },

  {
    title: "지원자",
    dataIndex: "workApplyCount",
    key: "workApplyCount",
    ellipsis: true,
    render: (workApplyCount: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              지원자
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {workApplyCount}
        </Typography>
      </MDBox>
    ),
  },
  {
    title: "지원승인",
    dataIndex: "currentRecruitment",
    key: "currentRecruitment",
    ellipsis: true,
    render: (currentRecruitment: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              지원승인
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {currentRecruitment}
        </Typography>
      </MDBox>
    ),
  },
  {
    title: "출근자",
    dataIndex: "goWorkCount",
    key: "goWorkCount",
    ellipsis: true,
    render: (currentRecruitment: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              출근자
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          -
        </Typography>
      </MDBox>
    ),
  },
  {
    title: "결근자",
    dataIndex: "workAbsenceCount",
    key: "workAbsenceCount",
    ellipsis: true,
    render: (workAbsenceCount: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              결근자
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          -
        </Typography>
      </MDBox>
    ),
  },
];

export { tableColumn };
