/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
// import Analytics from "layouts/dashboards/analytics";

// import SiteList from "layouts/SiteList";

// @mui icons
import Icon from "@mui/material/Icon";
import MyProfile from "layouts/categories/adminManagement/myprofile";

// import AnnouncementList from "layouts/categories/announcementManagement/announcements/announcement-list";
import NewAnnouncement from "layouts/categories/announcementManagement/announcements/new-announcement";

// import SiteList from "layouts/categories/announcementManagement/sites/site-list";

import SignInBasic from "layouts/authentication/sign-in/basic";
// import AnnouncementDetails from "layouts/categories/announcementManagement/announcements/announcement-details";
import SiteDetails from "layouts/categories/announcementManagement/sites/site-details";
// import MatchingList from "layouts/categories/matchingManagement/matchings/matching-list";
// import MatchingDetails from "layouts/categories/matchingManagement/matchings/matching-details";
// import UserList from "layouts/categories/matchingManagement/users/user-list";
// import UserDetails from "layouts/categories/matchingManagement/users/user-details";
// import SettlementList from "layouts/categories/settlementManagement/settlements/settlement-list";
// import SettlementUserList from "layouts/categories/settlementManagement/users/user-list";
// import SettlementUserDetails from "layouts/categories/settlementManagement/users/user-details";
// import LaborHistoryList from "layouts/categories/laborManagement/laborHistoryManagement/history-list";
// import LaborRecordList from "layouts/categories/laborManagement/recordManagement/record-list";

// import LaborRecordDetails from "layouts/categories/laborManagement/recordManagement/record-details";
// import LaborHistoryDetails from "layouts/categories/laborManagement/laborHistoryManagement/history-details";
import Cover from "layouts/authentication/sign-up/cover";
import PartnerList from "layouts/categories/partnerManagement/partners/partner-list";
import PartnerDetails from "layouts/categories/partnerManagement/partners/partner-details";
import UpdateAnnouncement from "layouts/categories/announcementManagement/announcements/update-announcement";
import WorkerList from "layouts/categories/laborManagement/workerManagement/worker-list";
import WorkerDetails from "layouts/categories/laborManagement/workerManagement/worker-details";
// import AnnouncementBookList from "layouts/categories/announcementManagement/announcements/announcement-book-list";
import OnBoards from "layouts/dashboards/onboards";
import SiteListNew from "layouts/categories/announcementManagement/sites/site-list-new";
import SiteBookListNew from "layouts/categories/announcementManagement/sites/site-book-list-new";
// import NewBookAnnouncement from "layouts/categories/announcementManagement/announcements/new-book-announcement";
import SettlementListVer2 from "layouts/categories/settlementManagement/settlements/settlement-list-ver2";
import SettlementDetailsVer2 from "layouts/categories/settlementManagement/settlements/settlement-details-ver2";
import OnlineWorkerList from "layouts/categories/laborManagement/workerManagement/online-worker-list";
import NewBug from "layouts/categories/bugManagement/new-bug";
import BugList from "layouts/categories/bugManagement/bug-list";
import PerformanceEvaluation from "layouts/categories/workerFeedBack/performanceEvaluation";

const routes = [
  // 현장평가
  {
    name: "WokerFeedBack",
    key: "/wokerFeedBack",
    route: "/work/field/evaluation",
    component: <PerformanceEvaluation />,
    hidden: true,
  },
  // 대시보드
  {
    name: "Basic",
    key: "basic",
    route: "/authentication/sign-in/basic",
    component: <SignInBasic />,
    hidden: true,
  },
  {
    // type: "collapse",
    name: "회원가입",
    key: "signUp",
    route: "/authentication/sign-up/cover",
    component: <Cover />,
    hidden: true,
    // noCollapse: true,
  },

  {
    type: "collapse",
    name: "대시보드",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",
    component: <OnBoards />,
    noCollapse: true,
  },

  {
    name: "관리자 계정관리",
    key: "my-profile",
    route: "/my-profile",
    component: <MyProfile />,
    hidden: true,
  },

  // 공고관리
  {
    type: "collapse",
    name: "공고관리",
    key: "announcement-management",
    icon: <Icon fontSize="medium">announcement</Icon>,
    collapse: [
      {
        // 공고추가
        name: "공고추가",
        key: "new-announcement",
        route: "/announcement-management/new-announcement",
        component: <NewAnnouncement />,
        hidden: true,
      },
      {
        // 공고추가
        name: "예약공고추가",
        key: "new-book-announcement",
        route: "/announcement-management/new-book-announcement",
        component: <NewAnnouncement />,
        hidden: true,
      },
      {
        // 공고수정
        name: "공고수정",
        key: "update-announcement",
        route: "/announcement-management/update-announcement/:id",
        component: <UpdateAnnouncement />,
        hidden: true,
      },
      {
        // 현장관리
        name: "예약 공고 관리",
        key: "site-book-list-new",
        route: "/announcement-management/site-book-list-new",
        component: <SiteBookListNew />,
      },
      {
        // 현장관리
        name: "공고관리",
        key: "site-list-new",
        route: "/announcement-management/site-list-new",
        component: <SiteListNew />,
      },
      {
        // 현장관리 상세조회
        name: "공고관리 상세조회",
        key: "site-details",
        route: "/announcement-management/site-details/:id",
        component: <SiteDetails />,
        hidden: true,
      }, // {
      //   // 현장관리
      //   name: "공고관리",
      //   key: "site-list",
      //   route: "/announcement-management/site-list",
      //   component: <SiteList />,
      // },
    ],
  },
  // 근로관리
  {
    type: "collapse",
    name: "근로관리",
    key: "labor-management",
    icon: <Icon fontSize="medium">work</Icon>,
    collapse: [
      {
        // 근로자 관리
        name: "근무중 관리",
        key: "online-worker-list",
        route: "/labor-management/online-worker-list",
        component: <OnlineWorkerList />,
      },
      {
        // 근로자 관리
        name: "근로자 관리",
        key: "worker-list",
        route: "/labor-management/worker-list",
        component: <WorkerList />,
      },
      {
        // 근로자 상세조회
        name: "근로자 상세조회",
        key: "worker-detail",
        route: "/labor-management/worker-detail/:id",
        component: <WorkerDetails />,
        hidden: true,
      },
      // {
      //   // 이력관리
      //   name: "이력관리",
      //   key: "labor-history-list",
      //   route: "/labor-management/labor-history-list",
      //   component: <LaborHistoryList />,
      // },
      // {
      //   // 이력관리 상세조회
      //   name: "이력관리 상세조회",
      //   key: "labor-history-detail",
      //   route: "/labor-management/labor-history-detail/:id",
      //   component: <LaborHistoryDetails />,
      //   hidden: true,
      // },
      // {
      //   // 근로내역
      //   name: "근로내역",
      //   key: "record-list",
      //   route: "/labor-management/record-list",
      //   component: <LaborRecordList />,
      // },
      // {
      //   // 근로내역 상세조회
      //   name: "근로내역 상세조회",
      //   key: "record-detail",
      //   route: "/labor-management/record-detail/:id",
      //   component: <LaborRecordDetails />,
      //   hidden: true,
      // },
    ],
  },
  // 매칭관리
  // {
  //   type: "collapse",
  //   name: "매칭관리",
  //   key: "matching-management",
  //   icon: <Icon fontSize="medium">shuffle</Icon>,
  //   collapse: [
  //     {
  //       // 매칭 리스트
  //       name: "매칭 리스트",
  //       key: "matching-list",
  //       route: "/matching-management/matching-list",
  //       component: <MatchingList />,
  //     },
  //     {
  //       // 매칭 상세조회
  //       name: "매칭 상세조회",
  //       key: "matching-detail",
  //       route: "/matching-management/matching-detail/:id",
  //       component: <MatchingDetails />,
  //       hidden: true,
  //     },
  //   ],
  // },

  // 정산관리
  {
    type: "collapse",
    name: "정산관리",
    key: "settlement-management",
    icon: <Icon fontSize="medium">account_balance_wallet</Icon>,
    collapse: [
      {
        // 정산내역new
        name: "정산내역",
        key: "settlement-history-ver",
        route: "/settlement-management/settlement-history-ver",
        component: <SettlementListVer2 />,
      },
      {
        // 정산내역 상세조회
        name: "정산내역 상세조회",
        key: "settlement-history-ver-detail",
        route: "/settlement-management/settlement-history-ver-detail/:id",
        component: <SettlementDetailsVer2 />,
        hidden: true,
      },
      // {
      //   // 정산내역
      //   name: "정산내역",
      //   key: "settlement-history",
      //   route: "/settlement-management/settlement-history",
      //   component: <SettlementList />,
      // },
      // {
      //   // 정산내역 상세조회
      //   name: "정산내역 상세조회",
      //   key: "settlement-history-detail",
      //   route: "/settlement-management/settlement-history-detail/:id",
      //   component: <SiteList />,
      //   hidden: true,
      // },

      // {
      //   // 사용자
      //   name: "사용자",
      //   key: "user-list",
      //   route: "/settlement-management/user-list",
      //   component: <SettlementUserList />,
      // },
      // {
      //   // 사용자 상세조회
      //   name: "사용자 상세조회",
      //   key: "user-detail",
      //   route: "/settlement-management/user-detail/:id",
      //   component: <SettlementUserDetails />,
      //   hidden: true,
      // },
    ],
  },
  // 파트너관리
  {
    type: "collapse",
    name: "파트너관리",
    key: "partner-management",
    icon: <Icon fontSize="medium">handshake</Icon>,
    collapse: [
      {
        // 현장 파트너 관리
        name: "현장 파트너 관리",
        key: "site-partner-list",
        route: "/site-partner-management/site-partner-list",
        component: <PartnerList />,
      },
      {
        // 현장 파트너 상세 조회
        name: "현장 파트너 상세 조회",
        key: "site-partner-detail",
        route: "/site-partner-management/site-partner-detail/:id",
        component: <PartnerDetails />,
        hidden: true,
      },
    ],
  },
  // 버그관리
  {
    type: "collapse",
    name: "에러제보",
    key: "bug-management",
    icon: <Icon fontSize="medium">support</Icon>,
    collapse: [
      {
        name: "에러접수내역",
        key: "bug-list",
        route: "/bug-management/bug-list",
        component: <BugList />,
      },
      // {
      //   name: "Bug관리",
      //   key: "bug-list-alpha",
      //   route: "/bug-management/bug-list-alpha",
      //   component: <BugListAlpha />,
      //   hidden: true,
      // },
      {
        name: "에러제보",
        key: "new-bug",
        route: "/bug-management/new-bug",
        component: <NewBug />,
        hidden: true,
      },
      // {
      //   name: "Bug상세",
      //   key: "qna-details",
      //   route: "/bug-management/bug-details/:id",
      //   component: <BugDetails />,
      //   hidden: true,
      // },
    ],
  },
];

export default routes;
