import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ReactNode } from "react";

interface Props {
  label: string;
  values: ReactNode;
}
function isString(value: ReactNode): value is string {
  return typeof value === "string";
}
function ListItem({ label, values }: Props): JSX.Element {
  if (values === null) return null;
  return (
    <MDBox key={label} display="flex" py={1} pr={2} alignItems="center">
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp; &nbsp;
      </MDTypography>
      {isString(values) ? (
        <MDTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{values}
        </MDTypography>
      ) : (
        values
      )}
    </MDBox>
  );
}

export default ListItem;
