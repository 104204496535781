import { Table, message } from "antd";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import DetailApplicantDialog from "./components/DetailApplicantDialog";
import { useEffect, useState } from "react";

import Apis from "apis/remotes";
import { IMatchCurrentSix } from "apis/response";
import { Rating, Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import MDButton from "components/MDButton";
import StarIcon from "@mui/icons-material/Star";
import { calculateAge } from "utils/utilDate";
import RenderUserScore from "components/RenderUserScore";
import { inputToPhone } from "utils/PhoneString";
interface Props {
  workId: string | number;
}
/** 평가 컴포넌트 */
function PerformanceEvaluation({ workId }: Props): JSX.Element {
  const [applicants, setApplicants] = useState<IMatchCurrentSix[]>([]);

  const tableColumn: ColumnsType<IMatchCurrentSix> = [
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      align: "center",
      render: (name: string, record) => (
        <MDBox textAlign={"center"}>
          {record.isDeleted ? (
            <p style={{ color: "red", fontSize: 12 }}>탈퇴회원</p>
          ) : record.isPartner === true ? (
            <p style={{ color: "orange", fontSize: 12 }}>파트너</p>
          ) : null}
          {/* {record.isPartner === true ? (
            <p style={{ color: "orange", fontSize: 12 }}>파트너</p>
          ) : null} */}
          <span>{name}</span>
        </MDBox>
      ),
    },
    {
      title: "평점",
      dataIndex: "score",
      key: "score",
      render: RenderUserScore,
    },

    {
      title: "나이",
      dataIndex: "birth",
      key: "birth",
      render: (birth: string) => calculateAge(birth ?? "000513"),
    },
    {
      title: "연락처",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
      render: (phone: string) => inputToPhone(phone),
    },
    {
      title: (
        <p>
          해당 현장 <br />
          출근 수
        </p>
      ),
      dataIndex: "targetWorkCount",
      key: "targetWorkCount",
      align: "center",
      render: (targetWorkCount: number) =>
        targetWorkCount > 0 ? (
          `${targetWorkCount}회 출근`
        ) : (
          <Typography color="error" variant="h6">
            최초 출근
          </Typography>
        ),
    },
    {
      title: (
        <p>
          최근 10회 <br />
          근무 결근 수
        </p>
      ),
      dataIndex: "recentAbsenceCount",
      key: "recentAbsenceCount",
      align: "center",
      render: (recentAbsenceCount: number) => {
        switch (recentAbsenceCount) {
          case 0:
            return "-";
          case 1:
            return 1;
          case 2:
            return 2;
          default:
            return (
              <Typography color="error" variant="h6">
                {recentAbsenceCount}
              </Typography>
            );
        }
      },
    },
    // {
    //   title: "혈압",
    //   dataIndex: "bloodPressure",
    //   key: "bloodPressure",
    //   align: "center",
    //   render: (bloodPressure: string) => "정상",
    // },
    {
      title: "평가",
      dataIndex: "score", // 이 필드는 실제 사용되지 않지만, render 함수에 데이터를 전달하기 위해 필요합니다.
      key: "score",
      ellipsis: true,
      render: (score, record) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            // const evaluation = formData.get("evaluation");
            const newScore = formData.get("score");
            // console.log("평가사유:", evaluation);
            console.log("평가점수:", newScore);
            if (newScore === null) return;
            if (score) {
              /** 수정 */
              Apis.putMatchEvaluationById(record.userEvaluationId, {
                score: Number(newScore),
                // evaluation: evaluation.toString(),
              })
                .then((resolve) => {
                  message.success("평가 수정 완료");
                  setApplicants((prev) =>
                    prev.map((applicant) => {
                      if (applicant.userApplicantId === record.userApplicantId) {
                        return {
                          ...applicant,
                          score: Number(newScore),
                          // evaluation: evaluation.toString(),
                        };
                      } else {
                        return applicant;
                      }
                    })
                  );
                })
                .catch((error) => {
                  message.error(error.response.data.message ?? "");
                });
            } else {
              /** 저장 */
              Apis.postMatchEvaluation({
                userApplicantId: record.userApplicantId,
                userId: record.userId,
                score: Number(newScore),
                evaluation: "",
              })
                .then((resolve) => {
                  message.success("평가 등록 완료");
                  setApplicants((prev) =>
                    prev.map((applicant) => {
                      if (applicant.userApplicantId === record.userApplicantId) {
                        return {
                          ...applicant,
                          score: Number(newScore),
                          // evaluation: evaluation.toString(),
                        };
                      } else {
                        return applicant;
                      }
                    })
                  );
                })
                .catch((error) => {
                  message.error(error.response.data.message ?? "");
                });
            }
          }}
        >
          <MDBox display={"flex"} gap={2} alignItems={"center"}>
            <Rating
              onChange={(e, value) => {
                console.log(value);
              }}
              name="score"
              defaultValue={score}
            />
            {/* <MDInput
              fullWidth
              type="text"
              name="evaluation"
              autoComplete="off"
              placeholder="평가사유를 남겨주세요.(20자)"
              defaultValue={record.evaluation}
            /> */}
            {score === 0 ? (
              <MDButton size="small" color="info" type="submit">
                저장
              </MDButton>
            ) : (
              <MDButton size="small" color="info" type="submit">
                수정
              </MDButton>
            )}
          </MDBox>
        </form>
      ),
    },
  ];
  useEffect(() => {
    Apis.getMatchCurrentApplySix(workId).then((resolve) => {
      // userApplicantId: number;
      // workId: number;
      // userId: number;
      // userEvaluationId: number;
      // status: string;
      // createdAt: string;
      // name: string;
      // phone: string;
      // birth: string;
      // isPartner: boolean;
      // gender: string;
      // score: number;
      // evaluation: string;
      setApplicants(resolve.data.data);
    });
  }, [workId]);

  return (
    <MDBox>
      <MDTypography variant="h5">평가</MDTypography>
      <MDBox mt={3}>
        <MDBox mt={3}>
          <Table
            pagination={false}
            rowKey={(record) => record.userEvaluationId}
            columns={tableColumn}
            dataSource={applicants}
            scroll={{ x: "max-content" }}
          />
        </MDBox>
        {/* <DetailApplicantDialog
          open={open}
          handleOpenState={handleOpenState}
          selectedApplicant={selectedApplicant}
        /> */}
      </MDBox>
    </MDBox>
  );
}
export default PerformanceEvaluation;
