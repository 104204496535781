import { ColumnsType } from "antd/es/table";

import { Rating } from "@mui/material";
import { IExpWorkById, IExpWorkDayList } from "apis/response";
import MDTypography from "components/MDTypography";

const tableColumn: ColumnsType<IExpWorkById> = [
  {
    title: "근무일",
    dataIndex: "workDay",
    key: "workDay",
    ellipsis: true,
  },
  {
    title: "근무시간",
    dataIndex: "workTime",
    key: "workTime",
    ellipsis: true,
  },
  // {
  //   title: "출근시간",
  //   dataIndex: "workingTime",
  //   key: "workingTime",
  //   ellipsis: true,
  // },
  // {
  //   title: "퇴근시간",
  //   dataIndex: "quittingTime",
  //   key: "quittingTime",
  //   ellipsis: true,
  // },
  // {
  //   title: "인력사무소",
  //   dataIndex: "officeName",
  //   key: "officeName",
  //   ellipsis: true,
  // },
  {
    title: "공고명",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
    align: "left",
    //width: 200, // Set the intended width
    // Apply inline style for maxWidth to ensure it doesn't exceed 200px
    onHeaderCell: () => ({
      style: {
        maxWidth: "200px",
      },
    }),
    render: (text: string) => (
      <div
        style={{
          maxWidth: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </div>
    ),
  },
  {
    title: "현장주소",
    dataIndex: "address",
    key: "address",
    ellipsis: true,
  },

  {
    title: "평가",
    dataIndex: "score",
    key: "score",
    ellipsis: true,
    render: (score) => <Rating readOnly name="simple-controlled" value={score} />,
  },
  {
    title: "평가사유",
    dataIndex: "evaluation",
    key: "evaluation",
    ellipsis: true,
  },
];

const tableColumn2: ColumnsType<IExpWorkDayList> = [
  {
    title: "일자",
    dataIndex: "workDay",
    key: "workDay",
    ellipsis: true,
  },
  {
    title: "공고명",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
    onHeaderCell: () => ({
      style: {
        maxWidth: "300px",
      },
    }),
    render: (text: string) => (
      <div
        style={{
          maxWidth: "300px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </div>
    ),
  },
  // {
  //   title: "근로유형",
  //   dataIndex: "workerType",
  //   key: "workerType",
  //   ellipsis: true,
  //   align: "center",
  //   render: (workerType) => `보통인부`,
  // },
  {
    title: "정산금액",
    dataIndex: "totalAmount",
    key: "totalAmount",
    ellipsis: true,
    align: "right",
    render: (totalAmount: number) => `${Number(totalAmount ?? 0)?.toLocaleString()}원`,
  },

  {
    title: "평가",
    dataIndex: "score",
    key: "score",
    ellipsis: true,
    align: "center",
    render: (score) => (score === 0 ? "평가 없음" : <Rating readOnly value={score} />),
  },
  {
    title: "근무상태",
    dataIndex: "status",
    key: "status",
    ellipsis: true,
    align: "center",
    render: (status: string) => (status === "" ? "미확인" : status),
  },
];

export { tableColumn, tableColumn2 };
