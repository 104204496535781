import { Divider, Grid } from "@mui/material";
import Card from "@mui/material/Card";

import { Checkbox, DatePicker, InputNumber, Radio, UploadFile, message } from "antd";
import Form, { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import Input from "antd/es/input/Input";
import TextArea from "antd/es/input/TextArea";
import { UserOutlined, PhoneOutlined } from "@ant-design/icons";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { handleUploadCompMultiFiles } from "utils/Upload";
import Apis from "apis/remotes";
import { IWorkById, IWorkPostForm } from "apis/response";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import axios from "axios";
import MDImage from "components/MDImage";
import { defaultImageList } from "./data";

// import LoadAnnounceDialog from "../new-announcement/components/LoadAnnounceDialog";
// import LoadOriginAnnounceDialog from "../new-announcement/components/LoadOriginAnnounceDialog";
import { paramsFilter } from "utils/ParamsFilter";
import { handleInput } from "utils/PhoneString";

declare global {
  interface Window {
    daum: any; // `any` 대신 더 구체적인 타입을 사용할 수 있습니다.
  }
}
const { RangePicker } = TimePicker;
const disabledDate = (current: any) => {
  // 오늘 이후
  if (current < Date.now()) {
    return true;
  }
  return false;
};

function UpdateAnnouncement(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  // const [open, setOpen] = useState<boolean>(false);
  // const [openOrigin, setOpenOrigin] = useState<boolean>(false);
  // const handleOpenState = () => setOpen((prev) => !prev);
  // const handleOpenOriginState = () => setOpenOrigin((prev) => !prev);
  const [siteThumbnail, setSiteThumbnail] = useState<UploadFile[]>([]);
  const [latLng, setLatLng] = useState<{ lat: number; lng: number } | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [workDay, setWorkDay] = useState<dayjs.Dayjs | null>(null);
  const [loading, setLoading] = useState(false);
  // const [value, setValue] = useState(2);
  const [isOrigin, setIsOrigin] = useState(false);
  const [evaluationChecked, setEvaluationChecked] = useState(false); // 현장 평가 유무 상태

  // const onChange = (e: RadioChangeEvent) => {
  //   console.log("radio checked", e.target.value);
  //   setValue(e.target.value);
  // };

  // const onChangeSiteThumbnailFile: UploadProps["onChange"] = async ({
  //   file,
  //   fileList: newFileList,
  // }) => {
  //   if (file.status === "uploading") {
  //     // 업로드 중인 파일이 있으면 fileList만 업데이트하고 종료
  //     setSiteThumbnail(newFileList);
  //     return;
  //   }

  //   try {
  //     const image = await handleUploadCompMultiFiles("image", newFileList, "work");

  //     // 파일 업로드 성공 후, 미리보기 이미지를 업데이트합니다.
  //     // 이때, presignedData.url은 서버에서 반환한 파일의 URL입니다.
  //     setSiteThumbnail([
  //       {
  //         uid: file.uid,
  //         name: file.name,
  //         status: "done",
  //         url: image[0], // 서버에서 반환한 파일의 최종 URL
  //       },
  //     ]);
  //   } catch (error) {
  //     console.error("File upload failed:", error);
  //     message.error("파일 업로드에 실패했습니다.");
  //     // 업로드 실패 시, 현재 파일 리스트에서 해당 파일 제거
  //     setSiteThumbnail(newFileList.filter((f) => f.uid !== file.uid));
  //   }
  // };

  // const startEdit = (imageList: string[], stateFunc: Function) => {
  //   const imageFileList = imageList;
  //   const arr = [];
  //   for (let i = 0; i < imageFileList.length; i++) {
  //     const previewFile: UploadFile = {
  //       uid: `done file ${i}`,
  //       name: imageFileList[i],
  //       status: "done",
  //       url: `${process.env.REACT_APP_IMG_ENDPOINT}${imageFileList[i]}`,
  //     };
  //     arr.push(previewFile);
  //   }
  //   stateFunc(arr);
  // };

  const disabledStartDay = (current: dayjs.Dayjs) => {
    // workDay가 설정되지 않았다면, 모든 날짜를 선택 불가능하게 합니다.
    if (!workDay) return true;

    // 설정된 workDay와 현재 달력에서 반복되는 날짜(current)가 같은 날인지 비교합니다.
    // 같지 않다면 true를 반환하여 해당 날짜를 선택할 수 없게 합니다.
    return !current.isSame(workDay.subtract(1, "day"), "day");
  };
  const disabledEndDay = (current: dayjs.Dayjs) => {
    // workDay가 설정되지 않았다면, 모든 날짜를 선택 불가능하게 합니다.
    if (!workDay) return true;

    // 설정된 workDay와 현재 달력에서 반복되는 날짜(current)가 같은 날인지 비교합니다.
    // 같지 않다면 true를 반환하여 해당 날짜를 선택할 수 없게 합니다.
    return !current.isSame(workDay, "day");
  };
  const handleAutoFillByWorkDay = (workDay: dayjs.Dayjs | null) => {
    /** workDay를 지정한 경우 */
    if (workDay) {
      setWorkDay(workDay);
      const workStart = workDay.subtract(1, "day").hour(17).minute(0).second(0);
      const workEnd = workDay.hour(3).minute(0).second(0);
      form.setFieldsValue({
        workTime: [dayjs("07:00:00", "HH:mm:ss"), dayjs("17:00:00", "HH:mm:ss")], // Assuming the form accepts an array for workTime
        workStart: workStart,
        workEnd: workEnd,
      });
    } else {
      /** workDay를 reset한 경우 */
      setWorkDay(null);
      form.resetFields(["workTime", "workStart", "workEnd"]);
    }
  };
  const handleSetFormLoadAnnouncement = (data: IWorkById) => {
    const { fieldEvaluatorName, fieldEvaluatorPhone, ...rest } = data;
    Apis.getUtilImageRandom()
      .then((resolve) => {
        const ramdomList = resolve.data.data;
        // setRandomList(ramdomList);
        const total = resolve.data.total;
        const selectOne = ramdomList[Math.floor(Math.random() * total)];

        const data: UploadFile = {
          uid: "done file default-1",
          name: selectOne.image,
          status: "done",
          url: selectOne.image,
        };
        setSiteThumbnail([data]);
      })
      .catch((error) => {
        console.log(error);
        setSiteThumbnail([defaultImageList[Math.floor(Math.random() * 5)]]);
      });

    if (data?.lat && data?.lng) {
      setLatLng({ lat: Number(data.lat), lng: Number(data.lng) });
      setAddress(data.address);
    }

    const { title, content } = data;
    // 공고 제목을 title_first와 title_second로 분리
    let title_first = "";
    let title_second = "";

    if (title) {
      const titleParts = title.split("(");
      title_first = titleParts[0]?.trim();
      title_second = titleParts[1]?.replace(")", "").trim() || ""; // 괄호를 제거하고 공백 제거
    }

    // 제목 조건 검사: title_first와 title_second 모두 값이 있어야만 설정
    const isValidTitle = Boolean(title_first) && Boolean(title_second);

    // 내용 분석
    const contentLines = content.split("\n");
    let workContent = "";
    let bloodPressure = null;
    let foodBreakfast = false;
    let foodLunch = false;
    let parking = false;
    let etc = "";

    let foundContent = false;

    contentLines.forEach((line) => {
      if (line.startsWith("작업내용:")) {
        workContent = line.replace("작업내용:", "").trim();
        foundContent = true;
      } else if (line.startsWith("혈압")) {
        bloodPressure = parseInt(line.replace("혈압", "").replace("미만", "").trim(), 10);
        foundContent = true;
      } else if (line.includes("조식제공 O")) {
        foodBreakfast = true;
        foundContent = true;
      } else if (line.includes("중식제공 O")) {
        foodLunch = true;
        foundContent = true;
      } else if (line.includes("주차가능 O")) {
        parking = true;
        foundContent = true;
      } else if (line.startsWith("기타:")) {
        etc = line.replace("기타:", "").trim();
        foundContent = true;
      }
    });
    if (!foundContent) {
      etc = content;
      // 기본값 설정
      workContent = "";
      bloodPressure = 140;
      foodBreakfast = false;
      foodLunch = false;
      parking = false;
    }

    // fieldManagerName과 fieldManagerPhone 값에 따라 evaluationCheck 값을 설정
    const evaluationChecked = fieldEvaluatorName && fieldEvaluatorPhone ? "yes" : "no";
    setEvaluationChecked(evaluationChecked === "yes");
    form.setFieldsValue({
      ...data,
      title_first,
      title_second,
      workContent,
      bloodPressure,
      food: [...(foodBreakfast ? ["breakfast"] : []), ...(foodLunch ? ["lunch"] : [])],
      parking,
      etc,
      workStart: dayjs(data.workStart),
      workEnd: dayjs(data.workEnd),
      workDay: dayjs(data.workDay),
      workTime: [
        dayjs(data.workTime.split("~")[0], "HH:mm:ss"),
        dayjs(data.workTime.split("~")[1], "HH:mm:ss"),
      ],
      unitPrice: data.pay / data.manDay,
      fieldEvaluatorName,
      fieldEvaluatorPhone,
      evaluationCheck: evaluationChecked, // 현장 평가 유무 설정
    });
  };
  const handleUpdateAnnouncement = async (formData: IWorkPostForm) => {
    try {
      setLoading(true);
      const image = (await handleUploadCompMultiFiles("image", siteThumbnail, "work")) ?? [] ?? [];
      Apis.putWork(id, {
        ...formData,

        payType: "일급",
        image: image[0],
        workStart: dayjs(formData.workStart).format("YYYY-MM-DD HH:mm:ss"),
        workEnd: dayjs(formData.workEnd).format("YYYY-MM-DD HH:mm:ss"),
        workDay: dayjs(formData.workDay).format("YYYY-MM-DD HH:mm:ss"),
        workTime:
          dayjs(formData.workTime[0]).format("HH:mm:ss") +
          "~" +
          dayjs(formData.workTime[1]).format("HH:mm:ss"),
        lat: latLng.lat.toString(),
        lng: latLng.lng.toString(),
        address: address,
      })
        .then((resolve) => {
          message.success("공고가 수정되었습니다.");
          navigate(`/announcement-management/site-details/${id}`);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const validateSiteThumbnailFiles = (rule: any, value: any) => {
    if (siteThumbnail.length === 0) {
      return Promise.reject(new Error("현장 썸네일을 업로드하세요."));
    }
    return Promise.resolve();
  };
  const validateSiteLatLng = (rule: any, value: any) => {
    if (latLng === null || address === null) {
      return Promise.reject(new Error("현장 주소를 등록하세요."));
    }
    return Promise.resolve();
  };

  const handleAddressSelectionAndConversion = async () => {
    if (window.daum && window.daum.Postcode) {
      new window.daum.Postcode({
        oncomplete: async (data: any) => {
          const { address } = data;
          if (!address) {
            message.error("주소를 찾을 수 없습니다.");
            return;
          }

          try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
              params: { address: address, key: process.env.REACT_APP_GOOGLE_MAP_KEY },
            });
            const { lat, lng } = response.data.results[0].geometry.location;
            setLatLng({ lat, lng });
            setAddress(address as string);
            form.setFieldsValue({ address: address });
            form.setFieldValue("addressDetail", null);
            message.success("주소가 성공적으로 변환되었습니다.");
          } catch (error) {
            console.error(error);
            message.error("주소를 좌표로 변환하는 데 실패했습니다.");
          }
          // 새로 주소 등록하는순간 그때는 다른 현장으로 인식.
          // setSelectWork(null);
        },
      }).open();
    } else {
      message.error("Daum 우편번호 서비스를 이용할 수 없습니다.");
    }
  };

  useEffect(() => {
    // 2024.08.30 변경사항
    // 인력사무소는 슈퍼 유저인 경우만 수정 페이지 접근 가능하며 해당 유저도 모집완료된 공고만 아니면 수정가능함.(관리자와 같음.)
    // 일반 유저는 접근도 불가능함.
    Apis.getAuth().then((resolve) => {
      const roleType = resolve.data.role;
      if (roleType === "super") {
        Apis.getWorkById(id)
          .then((resolve) => {
            if (resolve.data.status === "모집완료") {
              message.warning("모집완료된 공고는 수정할 수 없습니다.");
              navigate(
                `/announcement-management/site-list-new${paramsFilter({
                  page: 1,
                })}`
              );
            } else {
              handleSetFormLoadAnnouncement(resolve.data);
              if (resolve.data.originWorkId) {
                setIsOrigin(true);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        message.warning("공고 수정 접근권한이 없습니다.");
        navigate(
          `/announcement-management/site-list-new${paramsFilter({
            page: 1,
          })}`
        );
      }
    });
    // Apis.getWorkById(id)
    //   .then((resolve) => {
    //     if (resolve.data.status === "모집완료") {
    //       message.warning("모집완료된 공고는 수정할 수 없습니다.");
    //       navigate(
    //         `/announcement-management/site-list-new${paramsFilter({
    //           page: 1,
    //         })}`
    //       );
    //     } else {
    //       handleSetFormLoadAnnouncement(resolve.data);
    //       if (resolve.data.originWorkId) {
    //         setIsOrigin(true);
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }, [id]);
  /** 공수 입력 -> 급여 * 단가 = 급여 변경 */
  const handleChangeManDay = (manDay: number) => {
    const unitPriceValue: number = form.getFieldValue("unitPrice") ?? 10000;
    console.log("unitPriceValue", unitPriceValue);
    const resultPay: number = unitPriceValue * manDay;
    console.log("resuresultPayltUnitPrice", resultPay);
    form.setFieldValue("pay", resultPay);
  };
  /** 단가 입력 -> 공수 * 단가 = 급여 변경 */
  const handleChangeUnitPrice = (unitPrice: number) => {
    const manDayValue: number = form.getFieldValue("manDay") ?? 1000;
    console.log("manDayValue", manDayValue);
    const resultPay: number = manDayValue * unitPrice;
    console.log("resultPay", resultPay);
    form.setFieldValue("pay", resultPay);
  };
  /** 급여 입력 -> 급여 / 공수 = 단가 변경 */
  const handleChangePay = (pay: number) => {
    const getManDay: number = form.getFieldValue("manDay") ?? 1;
    console.log("getManDay", getManDay);
    const resultUnitPrice = pay / getManDay;
    console.log("resultUnitPrice", resultUnitPrice);
    form.setFieldValue("unitPrice", resultUnitPrice);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Form
              labelCol={{ sm: { span: 4 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
              form={form}
              onFinish={handleUpdateAnnouncement}
              autoComplete="off"
              onValuesChange={(changedValues, allValues) => {
                if ("evaluationCheck" in changedValues) {
                  setEvaluationChecked(changedValues.evaluationCheck === "yes");
                }
                const {
                  company,
                  // title_first,
                  title_second,
                  workContent,
                  bloodPressure,
                  food = [],
                  parking,
                  etc,
                } = allValues as any;

                // selectWork가 null일 때만 제목 자동 완성 기능 적용
                if (!isOrigin) {
                  // title_first와 title_second가 둘 중 하나라도 있는 경우 처리
                  let newTitle = company || "";

                  if (title_second) {
                    newTitle += ` (${title_second})`; // title_second가 있는 경우 괄호로 감쌈
                  }

                  form.setFieldsValue({ title: newTitle.trim() });
                }

                // content 필드 업데이트
                let content = "";

                // 작업내용이 있는 경우 추가
                if (workContent) {
                  content += `작업내용: ${workContent}\n`;
                }

                // 혈압 값이 있는 경우 추가
                if (bloodPressure !== undefined) {
                  content += `혈압 ${bloodPressure} 미만\n`;
                }

                // 식사제공 옵션 추가
                content += "조식제공 ";
                content += food.includes("breakfast") ? "O " : "X ";

                content += "중식제공 ";
                content += food.includes("lunch") ? "O " : "X ";
                content += "\n"; // 줄바꿈

                // 주차 가능 여부 추가
                content += "주차가능 ";
                content += parking ? "O\n" : "X\n"; // 주차가능이 체크된 경우 'v' 아니면 'x'

                // 기타 내용 추가
                if (etc) {
                  content += `기타: ${etc}\n`;
                }

                form.setFieldsValue({ content: content.trim() }); // content 필드를 업데이트
              }}
            >
              <FormItem
                label="현장 썸네일"
                name="image"
                required
                rules={[{ validator: validateSiteThumbnailFiles }]}
              >
                <MDBox display={"flex"} gap={2}>
                  <MDImage
                    image={
                      siteThumbnail[0]?.url?.replace(process.env.REACT_APP_IMG_ENDPOINT, "") ?? ""
                    }
                    style={{ width: 150, height: 150, borderRadius: 15, objectFit: "contain" }}
                  />

                  {/* <MDBox>
                    <Radio.Group onChange={onChange} value={value}>
                      <MDBox display={"flex"} flexDirection={"column"} gap={2}>
                        <Radio value={2}>기본 이미지로 변경</Radio>{" "}
                        {value === 2 ? (
                          <MDBox display={"flex"} gap={2}>
                            {defaultImageList.map((item) => {
                              return (
                                <MDImage
                                  onClick={() => {
                                    setSiteThumbnail([item]);
                                  }}
                                  key={item.uid}
                                  image={item.url}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                    borderRadius: 15,
                                    opacity: siteThumbnail[0]?.uid === item.uid ? 0.6 : 1,
                                    border:
                                      siteThumbnail[0]?.uid === item.uid ? "2px solid black" : null,
                                  }}
                                />
                              );
                            })}
                          </MDBox>
                        ) : null}
                        <Radio value={3}>사진 불러오기</Radio>
                        {value === 3 ? (
                          <Upload.Dragger
                            style={{ width: "200px" }}
                            {...props}
                            multiple={false}
                            fileList={siteThumbnail}
                            onChange={onChangeSiteThumbnailFile}
                            accept="image/png, image/jpg, image/jpeg"
                            maxCount={1}
                          >
                            <PlusOutlined />
                          </Upload.Dragger>
                        ) : null}
                      </MDBox>
                    </Radio.Group>
                  </MDBox> */}
                </MDBox>
              </FormItem>
              {/* <FormItem
                label="본청"
                name="company"
                rules={[{ required: true, message: "본청을 입력하세요" }]}
              >
                <Input type="text" style={{ width: "200px" }} maxLength={20} showCount />
              </FormItem> */}

              <FormItem
                label="작업일"
                name="workDay"
                rules={[{ required: true, message: "작업일을 지정하세요" }]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  onChange={handleAutoFillByWorkDay}
                />
              </FormItem>

              <FormItem
                label="작업 시간"
                name="workTime"
                rules={[{ required: true, message: "작업시간을 지정하세요" }]}
              >
                <RangePicker format={"HH:mm"} placeholder={["시작시간", "종료시간"]} />
              </FormItem>

              <FormItem
                hidden={workDay === null ? true : false}
                label="공고 시작일"
                name="workStart"
                rules={[{ required: true, message: "공고 시작일을 지정하세요" }]}
              >
                <DatePicker
                  disabledDate={disabledStartDay}
                  // disabledTime={disabledStartTime}
                  placeholder="공고 시작일"
                  format="YYYY-MM-DD HH:mm"
                  showTime={{ format: "HH:mm", minuteStep: 30 }}
                />
              </FormItem>
              <FormItem
                hidden={workDay === null ? true : false}
                label="공고 종료일"
                name="workEnd"
                rules={[{ required: true, message: "공고 종료일을 지정하세요" }]}
              >
                <DatePicker
                  disabledDate={disabledEndDay}
                  // disabledTime={disabledEndTime}
                  placeholder="공고 종료일"
                  format="YYYY-MM-DD HH:mm"
                  showTime={{ format: "HH:mm", minuteStep: 30 }}
                />
              </FormItem>
              <Divider />
              <FormItem
                label="공고 담당자"
                name="managerName"
                rules={[{ required: true, message: "공고 담당자를 입력하세요" }]}
              >
                <Input
                  type="text"
                  placeholder="공고 담당자"
                  prefix={<UserOutlined />}
                  style={{ maxWidth: 400 }}
                  maxLength={20}
                  showCount
                />
              </FormItem>
              <FormItem
                label="공고 담당자 연락처"
                name="managerPhone"
                rules={[{ required: true, message: "공고 담당자 연락처를 입력하세요" }]}
              >
                <Input
                  type="tel"
                  placeholder="공고 담당자 연락처"
                  prefix={<PhoneOutlined />}
                  style={{ maxWidth: 400 }}
                  maxLength={11}
                  showCount
                  onInput={handleInput}
                />
              </FormItem>
              <Divider />
              <FormItem
                label="현장 담당자"
                name="fieldManagerName"
                rules={[{ required: true, message: "현장 담당자를 입력하세요" }]}
              >
                <Input
                  type="text"
                  placeholder="현장 담당자"
                  prefix={<UserOutlined />}
                  style={{ maxWidth: 400 }}
                  maxLength={20}
                  showCount
                />
              </FormItem>
              <FormItem
                label="현장 담당자 연락처"
                name="fieldManagerPhone"
                rules={[{ required: true, message: "현장 담당자 연락처를 입력하세요" }]}
              >
                <Input
                  type="tel"
                  placeholder="현장 담당자 연락처"
                  prefix={<PhoneOutlined />}
                  style={{ maxWidth: 400 }}
                  maxLength={11}
                  showCount
                  onInput={handleInput}
                />
              </FormItem>
              <Divider />
              <FormItem
                name="evaluationCheck"
                label="현장 평가 유무"
                initialValue="no" // 기본값은 아니오
              >
                <Radio.Group>
                  <Radio value="yes">예</Radio>
                  <Radio value="no">아니오</Radio>
                </Radio.Group>
              </FormItem>
              {evaluationChecked && (
                <>
                  <FormItem
                    label="현장 평가 담당자"
                    name="fieldEvaluatorName"
                    rules={[{ required: true, message: "현장 평가 담당자를 입력하세요" }]}
                  >
                    <Input
                      type="text"
                      placeholder="현장 담당자"
                      prefix={<UserOutlined />}
                      style={{ maxWidth: 400 }}
                      maxLength={20}
                      showCount
                    />
                  </FormItem>
                  <FormItem
                    label="현장 평가 담당자 연락처"
                    name="fieldEvaluatorPhone"
                    rules={[{ required: true, message: "현장 평가 담당자 연락처를 입력하세요" }]}
                  >
                    <Input
                      type="tel"
                      placeholder="현장 평가 담당자 연락처"
                      prefix={<PhoneOutlined />}
                      style={{ maxWidth: 400 }}
                      maxLength={11}
                      showCount
                      onInput={handleInput}
                    />
                  </FormItem>
                </>
              )}
              <Divider />

              <FormItem
                label="원청 명"
                name="company"
                rules={[
                  { required: true, message: "원청 명을 입력하세요" },
                  {
                    validator: (_, value) => {
                      if (value && value.trim() === "") {
                        return Promise.reject(new Error("공백만 입력할 수 없습니다."));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  disabled={isOrigin}
                  style={{ width: "400px" }}
                  type="text"
                  maxLength={20}
                  showCount
                  onInput={(e) => {
                    // 현재 입력값에서 "(" , ")" , "," 제거
                    const inputValue = e.currentTarget.value;
                    const filteredValue = inputValue.replace(/[()]/g, ""); // 정규식을 사용하여 특정 문자 제거
                    e.currentTarget.value = filteredValue; // 필터링된 값으로 업데이트
                  }}
                />
              </FormItem>
              <FormItem
                hidden={isOrigin}
                label="현장 명"
                name="title_second"
                rules={[
                  { required: true, message: "현장명을 입력하세요." },
                  {
                    validator: (_, value) => {
                      if (value && value.trim() === "") {
                        return Promise.reject(new Error("공백만 입력할 수 없습니다."));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  type="text"
                  disabled={isOrigin}
                  style={{ width: "400px" }}
                  maxLength={40}
                  showCount // onInput으로 들어오는 문자에서 "("",")"는 제한해.
                  onInput={(e) => {
                    // 현재 입력값에서 "(" , ")" , "," 제거
                    const inputValue = e.currentTarget.value;
                    const filteredValue = inputValue.replace(/[()]/g, ""); // 정규식을 사용하여 특정 문자 제거
                    e.currentTarget.value = filteredValue; // 필터링된 값으로 업데이트
                  }}
                />
              </FormItem>
              <FormItem
                label="공고 제목"
                name="title"
                extra={isOrigin ? "동일 현장의 경우 공고제목 수정이 불가합니다." : null}
                rules={[{ required: true, message: "공고제목을 입력하세요" }]}
              >
                <Input type="text" disabled maxLength={40} showCount />
              </FormItem>
              <Divider />
              <FormItem label="공수 계산" required>
                <Grid container>
                  <Grid item xs={5} sm={3}>
                    <FormItem
                      extra="공수"
                      name="manDay"
                      rules={[
                        { required: true, message: "공수를 입력하세요" },
                        {
                          min: 1,
                          message: "최소공수는 1입니다.",
                          type: "number",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="공수(기본값:1)"
                        style={{ width: "100%" }}
                        min={1}
                        maxLength={2}
                        onChange={(e) => handleChangeManDay(e)}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item xs={2} sm={0.5}>
                    <MDBox textAlign={"center"}>X</MDBox>
                  </Grid>
                  <Grid item xs={5} sm={3}>
                    <FormItem
                      extra="단가"
                      name="unitPrice"
                      rules={[
                        { required: true, message: "단가를 입력하세요" },
                        { min: 1, message: "단가는 0원일 수 없습니다.", type: "number" },
                      ]}
                    >
                      <InputNumber
                        placeholder="단가"
                        formatter={(value: any) => {
                          // 소수점과 정수 부분을 분리
                          const [integerPart, decimalPart] = `${value}`.split(".");
                          // 정수 부분에만 콤마를 추가
                          const formattedIntegerPart = integerPart.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                          // 소수점 부분을 다시 붙여서 반환
                          return decimalPart
                            ? `${formattedIntegerPart}.${decimalPart}`
                            : formattedIntegerPart;
                        }}
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                        style={{ width: "100%" }}
                        maxLength={8}
                        onChange={(e) => handleChangeUnitPrice(e)}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item xs={2} sm={0.5}>
                    <MDBox textAlign={"center"}>=</MDBox>
                  </Grid>
                  <Grid item xs={10} sm={5}>
                    <FormItem
                      name="pay"
                      rules={[
                        { required: true, message: "급여를 입력하세요" },
                        { min: 1, message: "급여는 0원일 수 없습니다.", type: "number" },
                      ]}
                      extra="급여"
                    >
                      <InputNumber
                        placeholder="공수 X 단가 = 급여"
                        formatter={(value: any) => {
                          if (!value) return "";
                          // 소수점과 정수 부분을 분리
                          const [integerPart, decimalPart] = `${value}`.split(".");
                          // 정수 부분에만 콤마를 추가
                          const formattedIntegerPart = integerPart.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                          // 소수점 부분을 다시 붙여서 반환
                          return decimalPart !== undefined
                            ? `${formattedIntegerPart}.${decimalPart}`
                            : formattedIntegerPart;
                        }}
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                        style={{ width: "100%", maxWidth: "200px" }}
                        maxLength={8}
                        onChange={(e) => handleChangePay(e)}
                      />
                    </FormItem>
                  </Grid>
                </Grid>
              </FormItem>
              <Divider />
              <FormItem
                label="현장 주소"
                name="address"
                required
                rules={[
                  { validator: validateSiteLatLng },
                  //{ required: true, message: "현장 주소를 입력하세요" },
                ]}
                extra={
                  latLng && address ? (
                    <MDBox>
                      <Divider />
                      {address}
                      <FormItem
                        label="상세 주소"
                        name="addressDetail"
                        required
                        rules={[{ required: true, message: "상세 주소를 입력하세요." }]}
                      >
                        <Input max={80} style={{ maxWidth: "300px" }} type="text" />
                      </FormItem>
                    </MDBox>
                  ) : (
                    "주소를 등록하세요."
                  )
                }
              >
                <MDButton
                  // disabled={selectWork !== null ? true : false}
                  disabled={isOrigin}
                  size="small"
                  color="info"
                  onClick={() => handleAddressSelectionAndConversion()}
                >
                  주소찾기
                </MDButton>
              </FormItem>

              <Divider />

              <FormItem
                label="혈압"
                name="bloodPressure"
                rules={[{ required: true, message: "혈압 입력은 필수입니다." }]}
              >
                <InputNumber addonAfter="미만" min={50} max={300} />
              </FormItem>
              <FormItem label="식사제공" name="food">
                <Checkbox.Group
                  options={[
                    { label: "조식제공", value: "breakfast" },
                    { label: "중식제공", value: "lunch" },
                  ]}
                />
              </FormItem>
              <FormItem label="주차가능" name="parking" valuePropName="checked">
                <Checkbox>주차가능</Checkbox>
              </FormItem>
              <FormItem
                label="작업내용"
                name="workContent"
                rules={[{ required: true, message: "작업내용 입력은 필수입니다." }]}
              >
                <Input maxLength={255} showCount />
              </FormItem>
              <FormItem label="기타" name="etc">
                <Input maxLength={255} showCount />
              </FormItem>
              <Divider />
              <FormItem
                label="내용"
                name="content"
                rules={[
                  {
                    required: true,
                    message: "작업내용, 혈압, 식사제공, 주차가능 입력이 필요합니다.",
                  },
                ]}
                // extra="작업내용, 혈압, 식사제공, 주차가능 입력이 필요합니다."
              >
                <TextArea
                  rows={6}
                  maxLength={255}
                  showCount
                  disabled
                  placeholder="작업내용, 혈압, 식사제공, 주차가능 입력이 필요합니다."
                />
              </FormItem>
              <FormItem
                label="요구 경력"
                name="requiredExperience"
                rules={[{ required: true, message: "요구 경력을 입력하세요" }]}
              >
                <Input maxLength={20} showCount />
              </FormItem>
              <FormItem
                label="모집 인원"
                name="maxRecruitment"
                rules={[
                  { min: 1, message: "최소모집인원은 1명입니다.", type: "number" },
                  { required: true, message: "모집 인원을 입력하세요" },
                ]}
              >
                <InputNumber style={{ minWidth: "200px" }} min={1} maxLength={3} max={100} />
              </FormItem>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Link to={"/announcement-management/announcement-list"}>
                    <MDButton fullWidth variant="contained" color="light">
                      취소
                    </MDButton>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <MDButton
                    fullWidth
                    variant="gradient"
                    color="info"
                    type="submit"
                    aria-disabled={loading}
                    disabled={loading}
                  >
                    수정
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
      {/* {open ? (
        <LoadAnnounceDialog
          open={open}
          handleOpenState={handleOpenState}
          handleSetFormLoadAnnouncement={handleSetFormLoadAnnouncement}
        />
      ) : null}
      {openOrigin ? (
        <LoadOriginAnnounceDialog
          open={openOrigin}
          handleOpenState={handleOpenOriginState}
          handleSetFormLoadAnnouncement={handleSetFormLoadAnnouncement}
        />
      ) : null} */}
    </DashboardLayout>
  );
}
export default UpdateAnnouncement;
