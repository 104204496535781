function convertPhoneString(phone: string): string {
  // 휴대전화 번호 형식 검사 및 변환 (010으로 시작하는 번호)
  if (/^010-?\d{4}-?\d{4}$/.test(phone)) {
    // '-'를 제거하고 다시 형식에 맞게 추가
    const digits = phone.replace(/-/g, "");
    return `${digits.slice(0, 3)}-${digits.slice(3, 7)}-${digits.slice(7, 11)}`;
  }
  // 집전화 또는 지역번호 형식 검사 및 변환
  else if (/^\d{2,3}-?\d{3,4}-?\d{4}$/.test(phone)) {
    const digits = phone.replace(/-/g, "");
    if (digits.length === 10) {
      // 지역번호가 2자리인 경우
      return `${digits.slice(0, 2)}-${digits.slice(2, 6)}-${digits.slice(6, 10)}`;
    } else if (digits.length > 10) {
      // 지역번호가 3자리인 경우
      return `${digits.slice(0, 3)}-${digits.slice(3, 7)}-${digits.slice(7, 11)}`;
    }
  }
  // 입력된 번호가 어떤 형식에도 맞지 않을 경우, 원본 반환
  return phone;
}

function inputToPhone(text: string): string {
  if (text === "") return "미입력";
  // 숫자만 남기고 나머지 문자 제거
  const digits = text.replace(/\D/g, "");

  // 숫자만 있는 문자열의 길이에 따라 처리
  if (digits.length === 0) {
    return text; // 숫자가 없으면 원본 반환
  } else if (digits.length <= 2) {
    return digits; // 2자리 이하 숫자는 형식을 알 수 없으므로 그대로 반환
  } else if (digits.startsWith("010") && digits.length <= 11) {
    // 핸드폰 번호일 경우
    if (digits.length <= 3) {
      return digits; // 010까지만 입력된 경우
    } else if (digits.length <= 7) {
      return `${digits.slice(0, 3)}-${digits.slice(3)}`; // 010-XXXX
    } else {
      return `${digits.slice(0, 3)}-${digits.slice(3, 7)}-${digits.slice(7)}`; // 010-XXXX-XXXX
    }
  } else if (digits.length <= 10) {
    // 집전화 또는 지역번호일 경우 (10자리 이하)
    if (digits.length <= 6) {
      return `${digits.slice(0, digits.length - 4)}-${digits.slice(digits.length - 4)}`; // XX-XXXX 또는 XXX-XXX
    } else {
      return `${digits.slice(0, digits.length - 7)}-${digits.slice(
        digits.length - 7,
        digits.length - 4
      )}-${digits.slice(digits.length - 4)}`; // XX-XXX-XXXX 또는 XXX-XXX-XXXX
    }
  } else {
    // 그 외의 경우는 원본 반환
    return text;
  }
}
const formatPhoneNumber = (input: string) => {
  const cleaned = input.replace(/\D/g, ""); // 숫자가 아닌 모든 문자를 제거
  // const match = cleaned.match(/^(\d{0,3})(\d{0,4})(\d{0,4})$/);
  // if (match) {
  //   const part1 = match[1];
  //   const part2 = match[2] ? `-${match[2]}` : "";
  //   const part3 = match[3] ? `-${match[3]}` : "";
  //   return `${part1}${part2}${part3}`;
  // }
  return cleaned;
};
const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
  const input = e.currentTarget;
  const formatted = formatPhoneNumber(input.value);
  input.value = formatted;
};

export { convertPhoneString, inputToPhone, handleInput };
