import { Card, Grid } from "@mui/material";
import { Pagination, PaginationProps, Table } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";

import { tableColumn } from "./components/data";
import Apis from "apis/remotes";
import { IWorkPartnerList } from "apis/response";
import { paramsFilter } from "utils/ParamsFilter";

function PartnerList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("search");

  const [workPartnerList, setWorkPartnerList] = useState<IWorkPartnerList[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page") ?? 1));
  const [total, setTotal] = useState(0);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/site-partner-management/site-partner-list${paramsFilter({
        search,
        page,
      })}`
    );
    setCurrentPage(page);
  };

  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/site-partner-management/site-partner-list${paramsFilter({
        search: text,
        page: 1,
      })}`
    );
  };

  useEffect(() => {
    Apis.getWorkPartnerList({ page: currentPage, perPage: 10, search: search })
      .then((resolve) => {
        setWorkPartnerList(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3} spacing={2}>
          <Grid item xs={12} sm={6}>
            {/* <MDButton variant="outlined" color="dark">
              엑셀 다운
            </MDButton> */}
          </Grid>

          <Grid item xs={6} sm={6}>
            <Search maxLength={30} placeholder="현장명을 검색하세요" onSearch={handleSearch} />
          </Grid>
        </Grid>

        <Card>
          <Table
            pagination={false}
            rowKey={(record) => record.workId}
            scroll={{ x: "max-content" }}
            dataSource={workPartnerList}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/site-partner-management/site-partner-detail/${record.workId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default PartnerList;
