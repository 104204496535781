/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { AppBar, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import TodayDashBoard from "./components/TodayDashBoard";
import TomorrowDashBoard from "./components/TomorrowDashBoard";
import Apis from "apis/remotes";
import { transformData, transformData2, transformData3 } from "./components/graphtransform";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";

import MixedChart from "examples/Charts/MixedChart";
import HorizontalBarChart from "./components/HorizontalBarChart";

function OnBoards(): JSX.Element {
  const [tabValue, setTabValue] = useState<number>(0);
  const [graphData, setGraphData] = useState<{ data1: any; data2: any; data3: any }>({
    data1: [],
    data2: [],
    data3: [],
  });

  function getStepContent(tabIndex: number): JSX.Element {
    switch (tabIndex) {
      case 0:
        return <TodayDashBoard />;
      case 1:
        return <TomorrowDashBoard />;
      default:
        return null;
    }
  }
  const handleSetTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    Apis.getDashBoardGraph()
      .then((resolve) => {
        console.log(resolve.data);
        setGraphData({
          data1: transformData(resolve.data.data1),
          data2: transformData2(resolve.data.data2),
          data3: transformData3(resolve.data.data3),
        });
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <MDBox minWidth={{ xs: "22rem", md: "25rem" }} mx="auto">
              <AppBar position="static">
                <Tabs value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    id="today"
                    label={
                      <MDBox
                        py={0.5}
                        px={2}
                        fontWeight={tabValue === 0 && 800}
                        color={tabValue === 0 && "info"}
                      >
                        {dayjs(new Date()).format("YYYY년 MM월 DD일")}
                      </MDBox>
                    }
                  />
                  <Tab
                    id="tomorrow"
                    label={
                      <MDBox
                        py={0.5}
                        px={2}
                        fontWeight={tabValue === 1 && 800}
                        color={tabValue === 1 && "info"}
                      >
                        {dayjs(new Date()).add(1, "day").format("YYYY년 MM월 DD일")}
                      </MDBox>
                    }
                  />
                </Tabs>
              </AppBar>
            </MDBox>
          </Grid>
        </Grid>
        {getStepContent(tabValue)}
      </MDBox>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <VerticalBarChart
            icon={{ color: "info", component: "leaderboard" }}
            title="등록공고"
            description=" "
            chart={graphData.data1}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <MixedChart
            icon={{ color: "info", component: "leaderboard" }}
            title="출근현황"
            description=" "
            chart={graphData.data2}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <HorizontalBarChart
            icon={{ color: "info", component: "leaderboard" }}
            title="모집율"
            description=" "
            chart={graphData.data3}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default OnBoards;
