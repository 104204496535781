import { ColumnsType } from "antd/es/table";
import { IBugs } from "apis/response";
import MDBadgeDot from "components/MDBadgeDot";
import { Image } from "antd";
import MDBox from "components/MDBox";

const tableColumn: ColumnsType<IBugs> = [
  {
    title: "제목",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
    render: (title: string) => <MDBox style={{ maxWidth: 200 }}>{title}</MDBox>,
  },
  {
    title: "제보 내용",
    dataIndex: "content",
    key: "content",
    ellipsis: true,
    render: (content: string) => <MDBox style={{ maxWidth: 300 }}>{content}</MDBox>,
  },
  {
    title: "캡쳐화면",
    dataIndex: "image",
    key: "image",
    ellipsis: true,
    render: (image: string) => {
      if (!image.includes("images/origin")) {
        return "캡쳐화면 없음.";
      } else {
        return (
          <Image
            width={100}
            style={{ maxHeight: 100, objectFit: "contain" }}
            preview
            src={`${process.env.REACT_APP_IMG_ENDPOINT}` + image}
          />
        );
      }
    },
  },
  {
    title: "제보자 유형",
    dataIndex: "reporterType",
    key: "reporterType",
    ellipsis: true,
  },
  {
    title: "진행상황",
    dataIndex: "status",
    key: "status",
    ellipsis: true,
    render: (status: "에러 해결" | "에러 접수" | "보류") => {
      switch (status) {
        case "에러 접수":
          return (
            <MDBadgeDot badgeContent="에러 접수" size="sm" color="warning" variant="gradient" />
          );
        case "에러 해결":
          return (
            <MDBadgeDot badgeContent="에러 해결" size="sm" color="success" variant="gradient" />
          );
        case "보류":
          return <MDBadgeDot badgeContent="보류" size="sm" color="light" variant="gradient" />;
        default:
          return <MDBadgeDot badgeContent={status} size="sm" color="light" variant="gradient" />;
      }
    },
  },
];
export { tableColumn };
